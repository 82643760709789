import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCargo, tCargos } from "../../store/types/shipping.types";

import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import { getSkip } from "../../utils/func";

type tSelectCargo = {
  selectHandler: (cargo: tCargo) => void;
  open: boolean;
  closeHandler: () => void;
};

const SelectCargo = ({ selectHandler, open, closeHandler }: tSelectCargo) => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(1);
  const [division, setDivision] = useState<number>(8);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [cargos, setCargos] = useState<tCargos>([]);

  const close = () => {
    setSearch("");

    setPage(1);
    setDivision(8);

    closeHandler();
  };

  useEffect(() => {
    if (!open) return;

    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url: `/cargos?page=${page}&division=${division}&search=${search}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setCargos(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [search, page, division, accessToken, reload, open]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      <div className={cls("modal", open && "modal--open")}>
        <div className="modal__header">
          <h3 className="modal__heading">Select Cargo</h3>
          <div className="modal__filter">
            <input
              type="text"
              className="form-input"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="modal__actions">
            <span className="modal__action" onClick={close}>
              <IonIcon icon={closeOutline} />
            </span>
          </div>
        </div>
        <div className="modal__body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>HS Code</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      Error fetching cargo records.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !cargos.length ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      You have no cargo records
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && cargos.length
                  ? cargos.map((cargo) => (
                      <tr key={cargo._id}>
                        <td>{cargo.Description}</td>
                        <td>{cargo.HSCode}</td>
                        <td>
                          <button
                            className="btn"
                            onClick={() => {
                              selectHandler(cargo);
                              close();
                            }}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          {page !== 1 || cargos.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {open ? <div className="overlay" onClick={close}></div> : null}
    </>
  );
};

export default SelectCargo;
