import { useState, ReactNode } from "react";

import { tCarrier } from "../../store/types/shipping.types";
import SelectCarrier from "../../components/SelectCarrier/SelectCarrier";

type tTriggerFunction = React.Dispatch<
  React.SetStateAction<tCarrier | null>
> | null;

export type tSelectCarrierData = { type?: "Internal" | "External" };

const useSelectCarrier = (
  options: tSelectCarrierData = {}
): [ReactNode, (func: tTriggerFunction) => void] => {
  const [triggerFunction, setTriggerFunction] =
    useState<tTriggerFunction>(null);

  const defaultTriggerFunction = (carrier: tCarrier) => null;

  const updateTriggerFunction = (func: tTriggerFunction) => {
    setTriggerFunction(() => func);
  };

  const closeHandler = () => {
    setTriggerFunction(null);
  };

  const render = (
    <SelectCarrier
      selectHandler={triggerFunction || defaultTriggerFunction}
      open={!!triggerFunction}
      closeHandler={closeHandler}
      type={options.type}
    />
  );

  return [render, updateTriggerFunction];
};

export default useSelectCarrier;
