import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./hoc/ScrollToTop/ScrollToTop";

import Dashboard from "./pages/Dashboard/Dashboard";
import Shipments from "./pages/Shipments/Shipments";
import Invoice from "./pages/Invoice/Invoice";
import Waybill from "./pages/Waybill/Waybill";
import NewShipment from "./pages/NewShipment/NewShipment";
import ShipmentsImport from "./pages/ShipmentsImport/ShipmentsImport";
import SingleShipmentsImport from "./pages/SingleShipmentsImport/SingleShipmentsImport";
import Transactions from "./pages/Transactions/Transactions";

import Users from "./pages/Users/Users";
import Admins from "./pages/Admins/Admins";
import Branches from "./pages/Branches/Branches";
import Riders from "./pages/Riders/Riders";

import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/shipments" element={<Shipments />} />
        <Route path="/invoice/:id" element={<Invoice />} />
        <Route path="/waybill/:id" element={<Waybill />} />
        <Route path="/new-shipment" element={<NewShipment />} />
        <Route path="/edit-shipment/:id" element={<NewShipment />} />
        <Route path="/import" element={<ShipmentsImport />} />
        <Route path="/import/:id" element={<SingleShipmentsImport />} />
        <Route path="/users" element={<Users />} />
        <Route path="/admins" element={<Admins />} />
        <Route path="/branches" element={<Branches />} />
        <Route path="/riders" element={<Riders />} />
        <Route path="/transactions" element={<Transactions />} />

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
