import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./userReducer";
import cacheReducer from "./cacheReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    cache: cacheReducer,
  },
});

export type tRootState = ReturnType<typeof store.getState>;
