import { ReactNode } from "react";

const AuthLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div className="auth">
      <div className="auth__left"></div>
      <div className="auth__right">{children}</div>
    </div>
  );
};

export default AuthLayout;
