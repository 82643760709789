import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import {
  boatOutline,
  close,
  ellipsisVertical,
  receiptOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";

import api_client from "../../api/client";

import withAuth from "../../hoc/withAuth/withAuth";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import { copyData, getDate, getSkip, roundDP } from "../../utils/func";
import { tTransactions, tUser } from "../../store/types/app.types";

const Transactions = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const userId = searchParams.get("customer");

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [blockLoading, setBlockLoading] = useState(!!userId);

  const [user, setUser] = useState<tUser | null>(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [division] = useState<number>(20);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [transactions, setTransactions] = useState<tTransactions>([]);

  useEffect(() => {
    if (blockLoading) return;

    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    let url = `/wallet/transactions?page=${page}&division=${division}`;

    if (user?._id) url += `&user=${user._id}`;

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.data.meta_data.num_records);
          setPagination(res.data.data.meta_data.pagination);

          setTransactions(res.data.data.transactions);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [page, division, accessToken, reload, user?._id, blockLoading]);

  useEffect(() => {
    if (!userId) return setUser(null);

    api_client({
      url: `/users/${userId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        setUser(res.data.data.user);
      })
      .catch((err) => {})
      .finally(() => {
        setBlockLoading(false);
      });
  }, [userId, accessToken]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-header">
            <div className="shipments-header__left">
              <div className="shipments-header__header">
                <h3 className="shipments-header__heading">
                  Transaction History
                </h3>
                {user ? (
                  <div className="shipments-header__filter">
                    <p>
                      <strong>Customer:</strong> {user.Name}
                    </p>{" "}
                    <span
                      onClick={(e) =>
                        setSearchParams((sp) => {
                          sp.delete("customer");

                          return sp;
                        })
                      }
                    >
                      <IonIcon icon={close} />
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="shipments-header__actions"></div>
          </div>

          <div className="table-responsive shipments-table">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User</th>
                  <th>Transaction Type</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={20}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      Error fetching transactions.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !transactions.length ? (
                  <tr>
                    <td colSpan={20} className="text-center">
                      You have no transactions
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && transactions.length
                  ? transactions.map((transaction) => (
                      <tr key={transaction._id}>
                        <td>
                          <span onClick={() => copyData(transaction._id)}>
                            #{transaction._id.slice(transaction._id.length - 6)}
                          </span>
                        </td>
                        <td>
                          <div>
                            <p>
                              <strong>Name:</strong> {transaction.User.Name}
                            </p>
                            <p>
                              <strong>Company:</strong>{" "}
                              {transaction.User.Company}
                            </p>
                          </div>
                        </td>
                        <td>{transaction.Heading}</td>
                        <td>{transaction.Description}</td>
                        <td
                          className={
                            transaction.TransactionType === "SHIPMENT_CHARGE"
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {transaction.TransactionType === "SHIPMENT_CHARGE"
                            ? "-"
                            : "+"}{" "}
                          &#8358;{roundDP(transaction.Amount, 2)}
                        </td>
                        <td>{getDate(transaction.Date)}</td>
                        <td>
                          <div className="flex-center">
                            <div className="shipments-menu">
                              <div className="shipments-menu__main">
                                <IonIcon icon={ellipsisVertical} />
                              </div>
                              <div className="shipments-menu__block">
                                <div className="shipments-menu__block-main">
                                  {transaction.TransactionType ===
                                  "SHIPMENT_CHARGE" ? (
                                    <>
                                      <a
                                        href={`${process.env.PUBLIC_URL}/waybill/${transaction.ShipmentID}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <IonIcon icon={boatOutline} /> View
                                        Shipment Details
                                      </a>
                                      <a
                                        href={`${process.env.PUBLIC_URL}/invoice/${transaction.InvoiceID}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <IonIcon icon={receiptOutline} /> View
                                        Invoice Details
                                      </a>
                                    </>
                                  ) : null}
                                  {/* (
                                    <Link to="#">
                                      <IonIcon icon={boatOutline} /> View
                                      Transaction Details
                                    </Link>
                                  ) */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>

          <div className="shipments">
            {loading ? <VerticalBarLoader sm /> : null}
            {error ? (
              <div className="text-center">
                Error fetching transactions.{" "}
                <span className="link" onClick={() => setReload((rl) => !rl)}>
                  Reload
                </span>
              </div>
            ) : null}
            {!loading && !error && page === 1 && !transactions.length ? (
              <div className="text-center">You have no transactions</div>
            ) : null}
            {!loading && !error && transactions.length
              ? transactions.map((transaction) => (
                  <div className="shipment" key={transaction._id}>
                    <div className="shipment__header">
                      <p>
                        <strong>Transaction:</strong> #
                        {transaction._id.slice(transaction._id.length - 6)}
                      </p>
                      <div className="shipment__right">
                        <div className="flex-center">
                          <div className="shipments-menu">
                            <div className="shipments-menu__main">
                              <IonIcon icon={ellipsisVertical} />
                            </div>
                            <div className="shipments-menu__block">
                              <div className="shipments-menu__block-main">
                                {transaction.TransactionType ===
                                "SHIPMENT_CHARGE" ? (
                                  <>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/waybill/${transaction.ShipmentID}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <IonIcon icon={boatOutline} /> View
                                      Shipment Details
                                    </a>
                                    <a
                                      href={`${process.env.PUBLIC_URL}/invoice/${transaction.InvoiceID}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <IonIcon icon={receiptOutline} /> View
                                      Invoice Details
                                    </a>
                                  </>
                                ) : null}
                                {/* (
                                  <Link to="#">
                                    <IonIcon icon={boatOutline} /> View
                                    Transaction Details
                                  </Link>
                                ) */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="shipment__body">
                      <div className="mb-medium">
                        <p>
                          <strong>Name:</strong> {transaction.User.Name}
                        </p>
                        <p>
                          <strong>Company:</strong> {transaction.User.Company}
                        </p>
                      </div>
                      <p>
                        <strong>Transaction Type:</strong> {transaction.Heading}
                      </p>
                      <p>
                        <strong>Description:</strong> {transaction.Description}
                      </p>
                      <p>
                        <strong>Amount:</strong>{" "}
                        <span
                          className={
                            transaction.TransactionType === "SHIPMENT_CHARGE"
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {transaction.TransactionType === "SHIPMENT_CHARGE"
                            ? "-"
                            : "+"}{" "}
                          &#8358;{roundDP(transaction.Amount, 2)}
                        </span>
                      </p>
                      <p>
                        <strong>Date:</strong> {getDate(transaction.Date)}
                      </p>
                    </div>
                  </div>
                ))
              : null}
          </div>

          {page !== 1 || transactions.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Transactions);
