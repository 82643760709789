import { useState, ReactNode } from "react";

import { tBranch } from "../../store/types/app.types";
import SelectBranch from "../../components/SelectBranch/SelectBranch";

type tTriggerFunction = React.Dispatch<
  React.SetStateAction<tBranch | null>
> | null;

const useSelectBranch = (): [ReactNode, (func: tTriggerFunction) => void] => {
  const [triggerFunction, setTriggerFunction] =
    useState<tTriggerFunction>(null);

  const defaultTriggerFunction = (branch: tBranch) => null;

  const updateTriggerFunction = (func: tTriggerFunction) => {
    setTriggerFunction(() => func);
  };

  const closeHandler = () => {
    setTriggerFunction(null);
  };

  const render = (
    <SelectBranch
      selectHandler={triggerFunction || defaultTriggerFunction}
      open={!!triggerFunction}
      closeHandler={closeHandler}
    />
  );

  return [render, updateTriggerFunction];
};

export default useSelectBranch;
