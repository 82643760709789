import { useState, useEffect, useRef, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { IonIcon } from "@ionic/react";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";

import { login } from "../../store/userReducer";
import { updateOverview } from "../../store/cacheReducer";

import api_client from "../../api/client";

import useAlert from "../../hooks/useAlert/useAlert";

import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

import dmxLogo from "../../assets/img/dmx-logo-colored.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const submitBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const [message, setMessage, clearMessage] = useAlert();

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailAddress || !password)
      return setMessage("warning", "Fill in all fields");

    submitBtnRef.current.setAttribute("disabled", "disabled");
    submitBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;

    let data: any;

    api_client({
      url: "/admins/login",
      method: "POST",
      data: {
        EmailAddress: `${emailAddress}@dmxlogistics.com.ng`,
        Password: password,
      },
    })
      .then((res) => {
        data = res.data.data;

        return api_client({
          url: `/shipments/overview`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${data.accessToken}`,
          },
        });
      })
      .then((res) => {
        dispatch(login(data));
        dispatch(updateOverview(res.data.data));

        navigate("/");
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML = "Login";
        }
      });
  };

  useEffect(() => {
    clearMessage();
  }, [emailAddress, password, clearMessage]);

  return (
    <AuthLayout>
      <main className="auth__main">
        <div className="auth__logo-box">
          <img src={dmxLogo} alt="" className="auth__logo" />
        </div>
        <div className="auth__header">
          <h3 className="auth__heading">Welcome back,</h3>
          <p className="auth__sub-heading">Log into your account to continue</p>
        </div>
        <form className="auth__form" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email address</label>
            <div className="form-input-group form-input-group--2">
              <input
                type="text"
                placeholder="Enter email address"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <button type="button">@dmxlogistics.com.ng</button>
            </div>
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="form-input-group">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword((sp) => !sp)}
              >
                <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
              </button>
            </div>
          </div>
          <p>
            <Link to="/forgot-password" className="link">
              Forgot password?
            </Link>
          </p>
          <button
            className="button mt-small"
            disabled={!emailAddress || !password}
            ref={submitBtnRef}
          >
            Login
          </button>
          {message}
        </form>
      </main>
    </AuthLayout>
  );
};

export default Login;
