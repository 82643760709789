import { useState, useRef, useEffect, FormEvent } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  closeOutline,
  createOutline,
  ellipsisVerticalOutline,
  chevronDownOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tAdmins, tBranch, tCountry } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";
import { compSelectHandler } from "../NewShipment/NewShipment";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";

import { assertNotNull, getSkip, isNumber } from "../../utils/func";

const Admins = () => {
  const { accessToken, admin } = useSelector((state: tRootState) => state.user);

  assertNotNull(admin);

  const { countries, countriesSelectData, branches } = useSelector(
    (state: tRootState) => state.cache
  );

  const { fetchCountries, fetchBusinessSectors, fetchBranches } = useData();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(1);
  const [division] = useState<number>(8);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [admins, setAdmins] = useState<tAdmins>([]);

  const [addBranch, setAddAdmin] = useState(false);

  const [branch, setBranch] = useState<tBranch | null>(admin?.Branch || null);

  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [
    telephoneCountrySelectBox,
    selectedTelephoneCountryId,
    openTelephoneCountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [telephoneCountry, setTelephoneCountry] = useState<tCountry | null>(
    null
  );
  const [telephone, setTelephone] = useState<number | "">("");

  const addAdminBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert();

  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const clearAddBranchData = () => {
    setBranch(null);
    setName("");
    setEmailAddress("");
    setTelephoneCountry(null);
    setTelephone("");
  };

  const addNewAdmin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name || !emailAddress || !telephoneCountry || !telephone || !branch)
      return setMessage("warning", "Fill in all required fields");

    addAdminBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    addAdminBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/admins",
      method: "POST",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Name: name,
        EmailAddress: `${emailAddress}@dmxlogistics.com.ng`,
        TelephoneCountry: telephoneCountry._id,
        Telephone: telephone,
        Branch: branch._id,
      },
    })
      .then((res) => {
        setAddAdmin(false);
        clearAddBranchData();

        setSuccess({
          title: "Admin added!",
          message: "Admin added successfully",
        });

        setSearch("");
        setPage(1);
        setReload((rl) => !rl);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        addAdminBtnRef.current.removeAttribute("disabled");
        addAdminBtnRef.current.innerHTML = "Add Admin";
      });
  };

  useEffect(() => {
    if (!selectedTelephoneCountryId) return setTelephoneCountry(null);

    setTelephoneCountry(
      countries.find((ct) => ct._id === selectedTelephoneCountryId)!
    );
  }, [selectedTelephoneCountryId, countries]);

  useEffect(() => {
    clearMessage();
  }, [name, emailAddress, telephoneCountry, telephone, branch, clearMessage]);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url: `/admins?page=${page}&division=${division}&search=${search}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setAdmins(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [search, page, division, accessToken, reload]);

  useEffect(() => {
    fetchCountries();
    fetchBusinessSectors();
    fetchBranches();
  }, [fetchCountries, fetchBusinessSectors, fetchBranches]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      {telephoneCountrySelectBox}
      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p>{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}
      <div>
        <form
          className={cls(
            "modal modal--sm",
            addBranch && !telephoneCountrySelectBox && "modal--open"
          )}
          onSubmit={addNewAdmin}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Add New Admin</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => {
                  setAddAdmin(false);
                  clearAddBranchData();
                }}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              {message}
              <div className="form-group">
                <label>
                  Branch <span>*</span>
                </label>
                <select
                  className="form-select"
                  value={branch!?._id || ""}
                  onChange={compSelectHandler<tBranch>(branches, setBranch)}
                >
                  <option value="">-- Select Branch --</option>
                  {branches.map((brnch) => (
                    <option key={brnch._id} value={brnch._id}>
                      {brnch.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  Name <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>
                  Email address <span>*</span>
                </label>
                <div className="form-input-group form-input-group--2">
                  <input
                    type="text"
                    placeholder="Enter email address"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                  <button type="button">@dmxlogistics.com.ng</button>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Phone <span>*</span>
                </label>
                <div className={"input-group"}>
                  <button onClick={openTelephoneCountrySelectBox} type="button">
                    {telephoneCountry
                      ? telephoneCountry.TelephoneCode
                      : "Select"}{" "}
                    <IonIcon icon={chevronDownOutline} />
                  </button>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    value={telephone}
                    onChange={(e) =>
                      e.target.value
                        ? isNumber(e.target.value)
                          ? setTelephone(+e.target.value)
                          : null
                        : setTelephone("")
                    }
                    maxLength={10}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button
                className={cls(
                  "btn",
                  name &&
                    emailAddress &&
                    telephoneCountry &&
                    telephone &&
                    branch &&
                    "btn--primary"
                )}
                type="submit"
                ref={addAdminBtnRef}
              >
                Add Admin
              </button>
            </div>
          </div>
        </form>
        {addBranch && !telephoneCountrySelectBox ? (
          <div
            className="overlay"
            onClick={() => {
              setAddAdmin(false);
              clearAddBranchData();
            }}
          ></div>
        ) : null}
      </div>
      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-header">
            <div className="shipments-header__left">
              <h3 className="shipments-header__heading">Manage Admins</h3>
            </div>
            <div className="shipments-header__actions">
              <input
                type="text"
                className="form-input"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="btn btn--primary"
                style={{ flexShrink: 0 }}
                onClick={() => setAddAdmin(true)}
              >
                <IonIcon icon={addOutline} />
                New Admin
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Telephone Number</th>
                  <th>Status</th>
                  <th>Branch</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={10}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      Error fetching admins.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !admins.length ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      There are no admins at the moment
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && admins.length
                  ? admins.map((admin) => (
                      <tr key={admin._id}>
                        <td>{admin.Name}</td>
                        <td>{admin.EmailAddress}</td>
                        <td>{admin.Telephone}</td>
                        <td>{admin.Status}</td>
                        <td>{admin.Branch?.Name}</td>
                        <td>
                          <div className="shipments-menu">
                            <div className="shipments-menu__main">
                              <IonIcon icon={ellipsisVerticalOutline} />
                            </div>
                            <div className="shipments-menu__block">
                              <div className="shipments-menu__block-main">
                                <div>
                                  <IonIcon icon={createOutline} /> Update Admin
                                </div>
                              </div>
                              <div className="shipments-menu__block-top"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          {page !== 1 || branches.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Admins);
