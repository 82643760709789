import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  searchOutline,
  notificationsOutline,
  chevronDownOutline,
  volumeMuteOutline,
  personOutline,
  logOutOutline,
  caretDownOutline,
  boatOutline,
  addCircleOutline,
  gitPullRequestOutline,
  peopleOutline,
  businessOutline,
  menuOutline,
  closeOutline,
  bicycleOutline,
  barChartOutline,
} from "ionicons/icons";

import { tRootState } from "../../store";

import { assertNotNull } from "../../utils/func";

import dmxLogo from "../../assets/img/dmx-logo.png";
import { useState } from "react";

const SHIPMENTS_PATH = [
  "/shipments",
  "/shipments/",
  "/new-shipment",
  "/new-shipment/",
  "/import",
  "/import/",
];

const Header = () => {
  const admin = useSelector((state: tRootState) => state.user.admin);
  const pathname = useLocation().pathname;

  assertNotNull(admin);

  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <header className={cls("header", navbarOpen && "navbar--open")}>
      <div className="header__container container">
        {/* <div className="header__brand">
          <span>D</span>MX Logistics
        </div> */}
        <div className="header__brand">
          <div
            className="navbar__btn"
            onClick={() => setNavbarOpen((op) => !op)}
          >
            <IonIcon icon={menuOutline} />
            <IonIcon icon={closeOutline} />
          </div>
          <img src={dmxLogo} alt="" className="header__logo" />
        </div>
        <ul className="navbar">
          <li className="navbar__item">
            <NavLink
              to="/"
              className={({ isActive }) =>
                cls("navbar__link", isActive && "navbar__link--active")
              }
            >
              Dashboard
            </NavLink>
          </li>
          <li className="navbar__item navbar__item-has-submenu">
            <Link
              to="#"
              className={cls(
                "navbar__link",
                SHIPMENTS_PATH.includes(pathname) && "navbar__link--active"
              )}
            >
              Shipments <IonIcon icon={caretDownOutline} />
            </Link>
            <div className="navbar__sub-menu">
              <ul className="navbar__sub-menu-menu">
                <li className="navbar__sub-menu-item">
                  <NavLink
                    to="/shipments"
                    className={({ isActive }) =>
                      cls(
                        "navbar__sub-menu-link",
                        isActive && "navbar__sub-menu-link--active"
                      )
                    }
                  >
                    <IonIcon icon={boatOutline} />
                    Manage Shipments
                  </NavLink>
                </li>
                <li className="navbar__sub-menu-item">
                  <NavLink
                    to="/new-shipment"
                    className={({ isActive }) =>
                      cls(
                        "navbar__sub-menu-link",
                        isActive && "navbar__sub-menu-link--active"
                      )
                    }
                  >
                    <IonIcon icon={addCircleOutline} />
                    New Shipment
                  </NavLink>
                </li>
                <li className="navbar__sub-menu-item">
                  <NavLink
                    to="/import"
                    className={({ isActive }) =>
                      cls(
                        "navbar__sub-menu-link",
                        isActive && "navbar__sub-menu-link--active"
                      )
                    }
                  >
                    <IonIcon icon={gitPullRequestOutline} />
                    Import Shipments
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li className="navbar__item navbar__item-has-submenu">
            <Link to="#" className="navbar__link">
              Administration <IonIcon icon={caretDownOutline} />
            </Link>
            <div className="navbar__sub-menu">
              <ul className="navbar__sub-menu-menu">
                <li className="navbar__sub-menu-item">
                  <NavLink
                    to="/users"
                    className={({ isActive }) =>
                      cls(
                        "navbar__sub-menu-link",
                        isActive && "navbar__sub-menu-link--active"
                      )
                    }
                  >
                    <IonIcon icon={peopleOutline} />
                    Manage Customers
                  </NavLink>
                </li>
                <li className="navbar__sub-menu-item">
                  <NavLink
                    to="/riders"
                    className={({ isActive }) =>
                      cls(
                        "navbar__sub-menu-link",
                        isActive && "navbar__sub-menu-link--active"
                      )
                    }
                  >
                    <IonIcon icon={bicycleOutline} />
                    Manage Riders
                  </NavLink>
                </li>
                {/* <li className="navbar__sub-menu-item">
                  <NavLink
                    to="/contacts"
                    className={({ isActive }) =>
                      cls(
                        "navbar__sub-menu-link",
                        isActive && "navbar__sub-menu-link--active"
                      )
                    }
                  >
                    <IonIcon icon={locationOutline} />
                    Manage Contacts
                  </NavLink>
                </li> */}
                {/* <li className="navbar__sub-menu-item">
                  <NavLink
                    to="/invoices"
                    className={({ isActive }) =>
                      cls(
                        "navbar__sub-menu-link",
                        isActive && "navbar__sub-menu-link--active"
                      )
                    }
                  >
                    <IonIcon icon={documentTextOutline} />
                    Manage Invoices
                  </NavLink>
                </li> */}
                {admin.RoleGroup === "SuperAdmin" ? (
                  <>
                    <li className="navbar__sub-menu-item">
                      <NavLink
                        to="/transactions"
                        className={({ isActive }) =>
                          cls(
                            "navbar__sub-menu-link",
                            isActive && "navbar__sub-menu-link--active"
                          )
                        }
                      >
                        <IonIcon icon={barChartOutline} />
                        Manage Transactions
                      </NavLink>
                    </li>
                    <li className="navbar__sub-menu-item">
                      <NavLink
                        to="/admins"
                        className={({ isActive }) =>
                          cls(
                            "navbar__sub-menu-link",
                            isActive && "navbar__sub-menu-link--active"
                          )
                        }
                      >
                        <IonIcon icon={personOutline} />
                        Manage Admins
                      </NavLink>
                    </li>
                    <li className="navbar__sub-menu-item">
                      <NavLink
                        to="/branches"
                        className={({ isActive }) =>
                          cls(
                            "navbar__sub-menu-link",
                            isActive && "navbar__sub-menu-link--active"
                          )
                        }
                      >
                        <IonIcon icon={businessOutline} />
                        Manage Branches
                      </NavLink>
                    </li>
                    {/* <li className="navbar__sub-menu-item">
                      <NavLink
                        to="settings"
                        className={({ isActive }) =>
                          cls(
                            "navbar__sub-menu-link",
                            isActive && "navbar__sub-menu-link--active"
                          )
                        }
                      >
                        <IonIcon icon={settingsOutline} />
                        Settings
                      </NavLink>
                    </li> */}
                  </>
                ) : null}
              </ul>
            </div>
          </li>
        </ul>
        <div className="search-form">
          <IonIcon icon={searchOutline} />
          <input type="text" placeholder="Search anything" />
          <button>Find</button>
        </div>
        <div className="header__right">
          <span className="header__action">
            <IonIcon icon={volumeMuteOutline} />
          </span>
          <span className="header__action">
            <IonIcon icon={notificationsOutline} />
          </span>
          <div className="header__user">
            <div className="header__user-main">
              <img
                src={admin.ProfilePicturePath}
                alt=""
                className="header__user-img"
              />
              <IonIcon icon={chevronDownOutline} />
            </div>
            <div className="header__user-block">
              <div className="header__user-block-top"></div>
              <div className="header__user-block-main">
                <p className="header__user-email">{admin.EmailAddress}</p>
                <div className="header__user-user">
                  <img
                    src={admin.ProfilePicturePath}
                    alt=""
                    className="header__user-img"
                  />
                  <p className="header__user-name">Hi, {admin.Name}</p>
                  <span className="header__user-role badge badge--info">
                    {admin.RoleGroup}
                    {admin!.Branch ? ` - ${admin!.Branch.Name} Branch` : null}
                  </span>
                </div>
                <ul className="header__user-menu">
                  <li className="header__user-menu-item">
                    <Link to="/profile" className="header__user-menu-link">
                      <IonIcon icon={personOutline} />
                      Profile
                    </Link>
                  </li>
                  <li className="header__user-menu-item">
                    <Link to="/logout" className="header__user-menu-link">
                      <IonIcon icon={logOutOutline} />
                      Sign Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
