import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

type tSearchBox = {
  focus: boolean;
  url: string;
  search: string;
  displayKey: string;
  clickHandler: (value: any) => void;
};

const SearchBox = ({
  focus,
  url,
  search,
  displayKey,
  clickHandler,
}: tSearchBox) => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const mouseOver = useRef(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [results, setResults] = useState<any[]>([]);

  useEffect(() => {
    if (!search) return;

    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url: url.replace("{{search}}", search),
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setResults(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [url, search, accessToken, reload]);

  return !search || (!focus && !mouseOver.current) ? null : (
    <div
      className="pac-container pac-container--custom"
      onMouseOver={() => (mouseOver.current = true)}
      onMouseOut={() => (mouseOver.current = false)}
    >
      {loading ? (
        <div className="pac-item-1 pac-item--center">
          <VerticalBarLoader sm />
        </div>
      ) : null}
      {error ? (
        <div className="pac-item-1">
          Error loading search results.{" "}
          <span className="link" onClick={() => setReload((rl) => !rl)}>
            Reload
          </span>
        </div>
      ) : null}
      {!loading && !error && !results.length ? (
        <div className="pac-item-1">No results found</div>
      ) : null}
      {!loading && !error && results.length
        ? results.map((result) => (
            <div
              className="pac-item-1"
              key={result._id}
              onClick={() => {
                clickHandler(result);
                mouseOver.current = false;
              }}
            >
              {result[displayKey]}
            </div>
          ))
        : null}
    </div>
  );
};

export default SearchBox;
