import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";

import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import { getSkip } from "../../utils/func";
import { tRider, tRiders } from "../../store/types/app.types";

type tSingleRoute = { _id: string; name: string };

type tRouteQuery = [tSingleRoute, tSingleRoute];

export type tRoute = {
  routeType: "State" | "Country";
  country?: string;
  route: tRouteQuery;
} | null;

type tSelectRider = {
  selectHandler: (user: tRider) => void;
  open: boolean;
  closeHandler: () => void;
  route?: tRoute;
  carrier?: string;
};

const SelectRider = ({
  selectHandler,
  open,
  closeHandler,
  route,
  carrier,
}: tSelectRider) => {
  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(1);
  const [division, setDivision] = useState<number>(8);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [riders, setRiders] = useState<tRiders>([]);

  const close = () => {
    setSearch("");

    setPage(1);
    setDivision(8);

    closeHandler();
  };

  useEffect(() => {
    if (!open) return;

    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url: `/riders?page=${page}&division=${division}&search=${search}&status=ACTIVE${
          carrier ? `&carrier=${carrier}` : ""
        }${
          route
            ? `&routeType=${route.routeType}&route=${route.route
                .map((route) => route._id)
                .join("-")}${route.country ? `-${route.country}` : ""}`
            : ""
        }`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setRiders(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [search, page, division, accessToken, reload, route, open, carrier]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      <div className={cls("modal", open && "modal--open")}>
        <div className="modal__header">
          <h3 className="modal__heading">Select Rider</h3>
          <div className="modal__filter">
            <input
              type="text"
              className="form-input"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="modal__actions">
            <span className="modal__action" onClick={close}>
              <IonIcon icon={closeOutline} />
            </span>
          </div>
        </div>
        <div className="modal__body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Carrier</th>
                  {route ? <th>Route</th> : null}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      Error fetching riders.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !riders.length ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      There are no riders going this shipment route
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && riders.length
                  ? riders.map((rider) => (
                      <tr key={rider._id}>
                        <td>
                          <div className="rider">
                            <div className="rider">
                              <img src={rider.ProfilePicturePath} alt="" />
                              <p>
                                {rider.FirstName} {rider.LastName}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>{rider.Carrier?.Name}</td>
                        {route ? (
                          <td>
                            {route.route[0].name} &rArr; {route.route[1].name}
                          </td>
                        ) : null}
                        <td>
                          <button
                            className="btn"
                            onClick={() => {
                              selectHandler(rider);
                              close();
                            }}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          {page !== 1 || riders.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {open ? <div className="overlay" onClick={close}></div> : null}
    </>
  );
};

export default SelectRider;
