import { useState, useRef, useEffect, FormEvent } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  boatOutline,
  checkmark,
  chevronDownOutline,
  closeOutline,
  createOutline,
  ellipsisVerticalOutline,
  logInOutline,
  receiptOutline,
  walletOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tBranch, tCountry, tUsers } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import useAlert from "../../hooks/useAlert/useAlert";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";
import useData from "../../hooks/useData/useData";

import { assertNotNull, getSkip, isNumber, roundDP } from "../../utils/func";
import { compSelectHandler } from "../NewShipment/NewShipment";
import Spinner from "../../Loaders/Spinner/Spinner";

const Users = () => {
  const { accessToken, admin } = useSelector((state: tRootState) => state.user);

  assertNotNull(admin);

  const { countries, countriesSelectData, businessSectors, branches } =
    useSelector((state: tRootState) => state.cache);

  const { fetchCountries, fetchBusinessSectors, fetchBranches } = useData();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(1);
  const [division] = useState<number>(20);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [users, setUsers] = useState<tUsers>([]);

  // Add User
  const [userAction, setUserAction] = useState<{
    type: "add" | "update";
    userId?: string;
  } | null>(null);

  const [step, setStep] = useState<number>(1);

  const [personalInfoDone, setPersonalInfoDone] = useState(false);

  const [branch, setBranch] = useState<tBranch | null>(admin?.Branch || null);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [
    telephoneCountrySelectBox,
    selectedTelephoneCountryId,
    openTelephoneCountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [telephoneCountry, setTelephoneCountry] = useState<tCountry | null>(
    null
  );
  const [telephone, setTelephone] = useState<number | "">("");
  const [
    telephone2CountrySelectBox,
    selectedTelephone2CountryId,
    openTelephone2CountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [telephone2Country, setTelephone2Country] = useState<tCountry | null>(
    null
  );
  const [telephone2, setTelephone2] = useState<number | "">("");
  const [businessSector, setBusinessSector] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [referrer, setReferrer] = useState("");
  const [socialMediaHandles, setSocialMediaHandles] = useState("");

  const [shippingInfoDone, setShippingInfoDone] = useState(false);

  const addressRef = useRef<HTMLInputElement | null>(null);
  const [triggerAddressField, setTriggerAddressField] = useState(false);

  const [address, setAddress] = useState("");

  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState<tCountry | null>(null);

  const [billingInfoDone, setBillingInfoDone] = useState(false);

  const [billingMethod, setBillingMethod] = useState("Pre-paid Wallet");
  const [overdraft, setOverdraft] = useState<"Yes" | "No">("No");
  const [creditLimit, setCreditLimit] = useState<number | string>(0);

  const addUserBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert();
  // End Add User

  // Topup Wallet
  const [topupWallet, setTopupWallet] = useState("");

  const [amount, setAmount] = useState<number | string>("");

  const [tpMessage, tpSetMessage, tpClearMessage] = useAlert();

  const topupWalletBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  // End Topup Wallet

  const [showSpinner, setShowSpinner] = useState(false);

  const [error1, setError1] = useState("");
  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const [userAssignLogin, setUserAssignLogin] = useState("");
  const [confirmAssignLogin, setConfirmAssignLogin] = useState<
    "email" | "no-email" | ""
  >("");
  const [addEmail, setAddEmail] = useState(false);
  const [assignLoginEmail, setAssignLoginEmail] = useState("");
  const [assignLoginMessage, setAssignLoginMessage, clearAssignLoginMessage] =
    useAlert();

  const assignLogin = (userId: string, email?: string) => {
    setShowSpinner(true);

    const data: Partial<{ EmailAddress: string }> = {};

    if (email) data.EmailAddress = email;

    api_client({
      url: `/users/${userId}/assign-login`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    })
      .then((res) => {
        setUsers((usrs) =>
          usrs.map((user) =>
            user._id === res.data.data._id ? res.data.data : user
          )
        );
        setSuccess({
          title: "Login Assigned!",
          message:
            "User can now login with their email address and default password",
        });
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setError1(err.response.data.message);
        } else {
          setError1("An error occured while trying to assign login. Try again");
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const assignLoginFormHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!assignLoginEmail)
      return setAssignLoginMessage(
        "warning",
        "Email address is required to assign login"
      );

    setAddEmail(false);

    assignLogin(userAssignLogin, assignLoginEmail);
  };

  const clearTopupWalletData = () => {
    setAmount("");
    tpClearMessage();
  };

  const topupUserWallet = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!amount) return tpSetMessage("warning", "Amount is required");

    topupWalletBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    topupWalletBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: `/users/${topupWallet}/wallet-topup`,
      method: "PATCH",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Amount: amount,
      },
    })
      .then((res) => {
        setTopupWallet("");
        clearTopupWalletData();

        setSuccess({
          title: "Wallet Topup!",
          message: "User wallet topup successful",
        });

        setUsers((users) =>
          users.map((user) => (user._id === topupWallet ? res.data.data : user))
        );
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          tpSetMessage("warning", err.response.data.message);
        } else {
          tpSetMessage("error", err.message);
        }
      })
      .finally(() => {
        topupWalletBtnRef.current.removeAttribute("disabled");
        topupWalletBtnRef.current.innerHTML = "Topup";
      });
  };

  const clearUserData = () => {
    setName("");
    setCompany("");
    setEmailAddress("");
    setTelephoneCountry(null);
    setTelephone("");
    setTelephone2Country(null);
    setTelephone2("");
    setBusinessSector("");
    setIndustryType("");
    setReferrer("");
    setSocialMediaHandles("");

    setAddress("");
    setAddress2("");
    setAddress3("");
    setSuburb("");
    setCity("");
    setPostalCode("");
    setState("");
    setCountry(null);

    setBranch(admin.Branch || null);

    setBillingMethod("Pre-paid Wallet");
    setOverdraft("No");
    setCreditLimit(0);

    setStep(1);

    clearMessage();
  };

  const addUpdateUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!userAction) return;

    if (!personalInfoDone || !shippingInfoDone)
      return setMessage("warning", "Fill in all required fields");

    if (telephone.toString().length !== 10)
      return setMessage(
        "warning",
        "Telephone number should be 10 characters long"
      );

    const btnHTML = addUserBtnRef.current.innerHTML;

    addUserBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    addUserBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: userAction.type === "add" ? "/users" : `/users/${userAction.userId}`,
      method: userAction.type === "add" ? "POST" : "PUT",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Branch: branch!._id,
        Name: name,
        Company: company,
        EmailAddress: emailAddress,
        TelephoneCountry: telephoneCountry!._id,
        Telephone: telephone,
        Telephone2Country: telephone2Country?._id,
        Telephone2: telephone2,
        BusinessSector: businessSector,
        IndustryType: industryType,
        Referrer: referrer,
        SocialMediaHandles: socialMediaHandles,
        Address: address,
        Address2: address2,
        Address3: address3,
        PostalCode: postalCode,
        Suburb: suburb,
        City: city,
        Country: country!._id,
        State: state,
        Overdraft: overdraft === "Yes",
        CreditLimit: creditLimit,
      },
    })
      .then((res) => {
        setUserAction(null);
        clearUserData();

        setSuccess(
          userAction.type === "add"
            ? {
                title: "User added!",
                message: "User added successfully",
              }
            : { title: "User updated!", message: "User updated successfully" }
        );

        if (userAction.type === "add") {
          setSearch("");
          setPage(1);
          setReload((rl) => !rl);
        } else {
          setUsers((users) =>
            users.map((user) =>
              user._id === res.data.data._id ? res.data.data : user
            )
          );
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        addUserBtnRef.current.removeAttribute("disabled");
        addUserBtnRef.current.innerHTML = btnHTML;
      });
  };

  const updateUser = (userId: string) => {
    setShowSpinner(true);

    api_client({
      url: `/users/${userId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        const user: any = res.data.data.user;
        const contact: any = res.data.data.contact;

        setBranch(user.Branch);
        setName(user.Name);
        setCompany(user.Company);
        setEmailAddress(user.EmailAddress);
        setTelephoneCountry(user.TelephoneCountry);
        setTelephone(user.Telephone.slice(user.Telephone.length - 10));
        setTelephone2Country(user.Telephone2Country || null);
        setTelephone2(
          user.Telephone2
            ? +user.Telephone2.slice(user.Telephone2.length - 10)
            : ""
        );
        setBusinessSector(user.BusinessSector);
        setIndustryType(user.IndustryType);
        setReferrer(user.Referrer);
        setSocialMediaHandles(user.SocialMediaHandles);

        setAddress(contact.Address);
        setAddress2(contact.Address2);
        setAddress3(contact.Address3);
        setSuburb(contact.Suburb);
        setPostalCode(contact.PostalCode);
        setCountry(contact.Country);
        setState(contact.State);
        setCity(contact.City);

        setOverdraft(user.Overdraft ? "Yes" : "No");
        setCreditLimit(user.CreditLimit);

        setUserAction({ type: "update", userId: user._id });
      })
      .catch((err) => {})
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const previous = () => {
    if (step === 1) return;

    setStep(step - 1);
  };

  const next = () => {
    if (step === (admin.RoleGroup === "SuperAdmin" ? 3 : 2)) return;

    const newStep = step + 1;

    if (newStep === 2 && !personalInfoDone)
      return setMessage("warning", "Fill in all required fields");
    if (newStep === 3 && !shippingInfoDone)
      return setMessage("warning", "Fill in all required fields");

    setStep(newStep);
  };

  useEffect(() => {
    clearAssignLoginMessage();
  }, [clearAssignLoginMessage, assignLoginEmail]);

  useEffect(() => {
    tpClearMessage();
  }, [amount, tpClearMessage]);

  useEffect(() => {
    clearMessage();
  }, [
    name,
    company,
    emailAddress,
    telephoneCountry,
    telephone,
    businessSector,
    industryType,
    referrer,
    socialMediaHandles,
    address,
    address2,
    address3,
    suburb,
    postalCode,
    city,
    state,
    country,
    clearMessage,
  ]);

  useEffect(() => {
    const fillInAddress = (autocomplete: any) => {
      setAddress("");
      setAddress2("");
      setAddress3("");
      setSuburb("");
      setCity("");
      setPostalCode("");
      setState("");
      setCountry(null);

      const place = autocomplete.getPlace();

      console.log(place);

      let street: string = "";
      let route: string = "";

      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];

        if (addressType === "street_number") {
          street = place.address_components[i]["long_name"];
        }

        if (addressType === "route") {
          route = place.address_components[i]["long_name"];
        }

        if (!street && route) setAddress(route);
        if (street && !route) setAddress(street);
        if (street && route) setAddress(`${street}, ${route}`);

        if (addressType === "sublocality_level_1")
          setAddress(place.address_components[i]["long_name"]);

        if (addressType === "neighborhood")
          setSuburb(place.address_components[i]["long_name"]);

        if (addressType === "locality")
          setCity(place.address_components[i]["long_name"]);
        else if (addressType === "postal_town")
          setCity(place.address_components[i]["long_name"]);

        if (addressType === "administrative_area_level_1") {
          setState(place.address_components[i]["long_name"]);
        }

        if (addressType === "country") {
          const country =
            countries.find(
              (country) =>
                country.Code === place.address_components[i]["short_name"]
            ) || null;
          setCountry(country);
        }

        if (addressType === "postal_code")
          setPostalCode(place.address_components[i]["long_name"]);
      }
    };

    if (addressRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressRef.current,
        { types: ["geocode"] }
      );
      autocomplete.setFields(["address_component"]);
      autocomplete.addListener("place_changed", () =>
        fillInAddress(autocomplete)
      );
    }
  }, [countries, triggerAddressField]);

  useEffect(() => {
    setPersonalInfoDone(
      !!(
        name &&
        company &&
        telephoneCountry &&
        telephone &&
        businessSector &&
        branch
      )
    );
  }, [name, company, telephoneCountry, telephone, businessSector, branch]);

  useEffect(() => {
    setShippingInfoDone(!!(address && country && state && city));
  }, [address, country, state, city]);

  useEffect(() => {
    setBillingInfoDone(!!(billingMethod && overdraft && creditLimit !== ""));
  }, [billingMethod, overdraft, creditLimit]);

  useEffect(() => {
    if (!selectedTelephone2CountryId) return setTelephone2Country(null);

    setTelephone2Country(
      countries.find((ct) => ct._id === selectedTelephone2CountryId)!
    );
  }, [selectedTelephone2CountryId, countries]);

  useEffect(() => {
    if (!selectedTelephoneCountryId) return setTelephoneCountry(null);

    setTelephoneCountry(
      countries.find((ct) => ct._id === selectedTelephoneCountryId)!
    );
  }, [selectedTelephoneCountryId, countries]);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url: `/users?page=${page}&division=${division}&search=${search}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setUsers(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [search, page, division, accessToken, reload]);

  useEffect(() => {
    fetchCountries();
    fetchBusinessSectors();
    fetchBranches();
  }, [fetchCountries, fetchBusinessSectors, fetchBranches]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      {telephoneCountrySelectBox}
      {telephone2CountrySelectBox}

      {showSpinner ? <Spinner /> : null}

      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p className="text-center">{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}

      {error1 ? (
        <div>
          <div className="success-modal">
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            <p className="text-center">{error1}</p>
          </div>
          <div className="overlay" onClick={() => setError1("")}></div>
        </div>
      ) : null}

      {userAssignLogin && confirmAssignLogin ? (
        <div>
          <div className="success-modal">
            <h3>Confirmation</h3>
            <p className="text-center">
              Are you sure you want to assign login for this user
            </p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  setConfirmAssignLogin("");
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn--success"
                onClick={() => {
                  confirmAssignLogin === "no-email"
                    ? setAddEmail(true)
                    : assignLogin(userAssignLogin);
                  setConfirmAssignLogin("");
                }}
              >
                Continue
              </button>
            </div>
          </div>
          <div
            className="overlay"
            onClick={() => setConfirmAssignLogin("")}
          ></div>
        </div>
      ) : null}

      <div>
        <form
          className={cls("modal modal--xs", addEmail && "modal--open")}
          onSubmit={assignLoginFormHandler}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Assign Login</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setAddEmail(false)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>
                  Email Address <span>*</span>
                </label>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Enter email address"
                  value={assignLoginEmail}
                  onChange={(e) => setAssignLoginEmail(e.target.value)}
                />
              </div>
              {assignLoginMessage}
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button className="btn btn--primary" type="submit">
                Assign Login
              </button>
            </div>
          </div>
        </form>
        {addEmail ? (
          <div className="overlay" onClick={() => setAddEmail(false)}></div>
        ) : null}
      </div>

      <div>
        <form
          className={cls(
            "modal modal--md",
            userAction &&
              !telephoneCountrySelectBox &&
              !telephone2CountrySelectBox &&
              "modal--open"
          )}
          onSubmit={addUpdateUser}
        >
          <div className="modal__header">
            <h3 className="modal__heading">
              {userAction?.type === "update" ? "Update" : "Add New"} Customer
            </h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => {
                  setUserAction(null);
                  clearUserData();
                }}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-steps mb-medium">
              <div className="form-steps__step" onClick={() => setStep(1)}>
                <span
                  className={cls(
                    "form-steps__step-number",
                    personalInfoDone && "active"
                  )}
                >
                  {personalInfoDone ? <IonIcon icon={checkmark} /> : 1}
                </span>
                Personal Info
              </div>
              <div
                className={cls("form-steps__divider", step > 1 && "active")}
              ></div>
              <div
                className="form-steps__step"
                onClick={() => (personalInfoDone ? setStep(2) : null)}
              >
                <span
                  className={cls(
                    "form-steps__step-number",
                    shippingInfoDone && "active"
                  )}
                >
                  {shippingInfoDone ? <IonIcon icon={checkmark} /> : 2}
                </span>
                Shipping Info
              </div>
              {admin.RoleGroup === "SuperAdmin" ? (
                <>
                  <div
                    className={cls("form-steps__divider", step > 2 && "active")}
                  ></div>
                  <div
                    className="form-steps__step"
                    onClick={() => (shippingInfoDone ? setStep(3) : null)}
                  >
                    <span
                      className={cls(
                        "form-steps__step-number",
                        billingInfoDone && "active"
                      )}
                    >
                      {billingInfoDone ? <IonIcon icon={checkmark} /> : 3}
                    </span>
                    Billing Info
                  </div>
                </>
              ) : null}
            </div>
            <div className="form-flex">
              {message}
              <div className={cls("form-grid", step !== 1 && "hidden")}>
                {admin.RoleGroup === "SuperAdmin" ? (
                  <div className="form-group">
                    <label>
                      Branch <span>*</span>
                    </label>
                    <select
                      className="form-select"
                      value={branch!?._id || ""}
                      onChange={compSelectHandler<tBranch>(branches, setBranch)}
                    >
                      <option value="">-- Select Branch --</option>
                      {branches.map((brnch) => (
                        <option key={brnch._id} value={brnch._id}>
                          {brnch.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <div className="form-group">
                  <label>
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Company <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Email address</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Name"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Phone <span>*</span>
                  </label>
                  <div className={"input-group"}>
                    <button
                      onClick={openTelephoneCountrySelectBox}
                      type="button"
                    >
                      {telephoneCountry
                        ? telephoneCountry.TelephoneCode
                        : "Select"}{" "}
                      <IonIcon icon={chevronDownOutline} />
                    </button>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      value={telephone}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setTelephone(+e.target.value)
                            : null
                          : setTelephone("")
                      }
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Phone 2</label>
                  <div className={"input-group"}>
                    <button
                      onClick={openTelephone2CountrySelectBox}
                      type="button"
                    >
                      {telephone2Country
                        ? telephone2Country.TelephoneCode
                        : "Select"}{" "}
                      <IonIcon icon={chevronDownOutline} />
                    </button>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      value={telephone2}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setTelephone2(+e.target.value)
                            : null
                          : setTelephone2("")
                      }
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Business Sector <span>*</span>
                  </label>
                  <select
                    className="form-select"
                    value={businessSector}
                    onChange={(e) => setBusinessSector(e.target.value)}
                  >
                    <option value="">-- Select Business Sector --</option>
                    {businessSectors.map((sector) => (
                      <option key={sector._id} value={sector._id}>
                        {sector.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Industry Type</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter industry type"
                    value={industryType}
                    onChange={(e) => setIndustryType(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Referrer</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter referrer code"
                    value={referrer}
                    onChange={(e) => setReferrer(e.target.value)}
                  />
                </div>
                <div className="form-group grid-full">
                  <label>Social Media Handles</label>
                  <textarea
                    className="form-input"
                    placeholder="Enter social media handle separated by comma"
                    value={socialMediaHandles}
                    rows={3}
                    onChange={(e) => setSocialMediaHandles(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className={cls("form-grid", step !== 2 && "hidden")}>
                <div className="form-group">
                  <label>
                    Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    ref={(el) => {
                      addressRef.current = el;
                      setTriggerAddressField((taf) => !taf);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Address 2</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter address 2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Address 3</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter address 3"
                    value={address3}
                    onChange={(e) => setAddress3(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Suburb</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter suburb"
                    value={suburb}
                    onChange={(e) => setSuburb(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Postal code</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter postal code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Country <span>*</span>
                  </label>
                  <select
                    className="form-select"
                    value={country!?._id || ""}
                    onChange={compSelectHandler<tCountry>(
                      countries,
                      setCountry
                    )}
                  >
                    <option value="">-- Select Country --</option>
                    {countries.map((cuntry) => (
                      <option key={cuntry._id} value={cuntry._id}>
                        {cuntry.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    State <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    City <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
              <div className={cls("form-grid", step !== 3 && "hidden")}>
                <div className="form-group">
                  <label>Billing Type</label>
                  <select
                    className="form-select"
                    disabled
                    value={billingMethod}
                    onChange={(e) => setBillingMethod(e.target.value)}
                  >
                    <option>Pre-paid Wallet</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Overdraft</label>
                  <select
                    className="form-select"
                    value={overdraft}
                    onChange={(e) => setOverdraft(e.target.value as any)}
                  >
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
                {overdraft === "Yes" ? (
                  <div className="form-group">
                    <label>Credit Limit</label>
                    <div className="input-group">
                      <button>NGN</button>
                      <input
                        type="text"
                        placeholder="Enter declared value"
                        value={creditLimit}
                        onChange={(e) =>
                          e.target.value
                            ? isNumber(e.target.value)
                              ? setCreditLimit(e.target.value)
                              : null
                            : setCreditLimit("")
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <div>
              {step > 1 ? (
                <button className="btn" type="button" onClick={previous}>
                  Previous
                </button>
              ) : null}
            </div>
            <div>
              {step === (admin.RoleGroup === "SuperAdmin" ? 3 : 2) ? (
                <button
                  className={cls("btn", shippingInfoDone && "btn--primary")}
                  type="submit"
                  ref={addUserBtnRef}
                >
                  {userAction?.type === "update" ? "Update" : "Add"} Customer
                </button>
              ) : null}
              {step < (admin.RoleGroup === "SuperAdmin" ? 3 : 2) ? (
                <button
                  className={cls(
                    "btn",
                    ((step === 1 && personalInfoDone) ||
                      (step === 2 && shippingInfoDone)) &&
                      "btn--primary"
                  )}
                  type="button"
                  onClick={next}
                >
                  Next
                </button>
              ) : null}
            </div>
          </div>
        </form>
        {userAction &&
        !telephoneCountrySelectBox &&
        !telephone2CountrySelectBox ? (
          <div
            className="overlay"
            onClick={() => {
              setUserAction(null);
              clearUserData();
            }}
          ></div>
        ) : null}
      </div>

      <div>
        <form
          className={cls("modal modal--sm", topupWallet && "modal--open")}
          onSubmit={topupUserWallet}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Topup Wallet</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => {
                  setTopupWallet("");
                  clearTopupWalletData();
                }}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>
                  Amount <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <button>NGN</button>
                  <input
                    type="text"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) =>
                      e.target.value
                        ? isNumber(e.target.value)
                          ? setAmount(e.target.value)
                          : null
                        : setAmount("")
                    }
                  />
                </div>
              </div>
              {tpMessage}
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button
                className={cls("btn", amount && "btn--primary")}
                type="submit"
                ref={topupWalletBtnRef}
              >
                Topup
              </button>
            </div>
          </div>
        </form>
        {topupWallet ? (
          <div
            className="overlay"
            onClick={() => {
              setTopupWallet("");
              clearTopupWalletData();
            }}
          ></div>
        ) : null}
      </div>

      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-header">
            <div className="shipments-header__left">
              <h3 className="shipments-header__heading">Manage Customers</h3>
            </div>
            <div className="shipments-header__actions">
              <input
                type="text"
                className="form-input"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="btn btn--primary"
                style={{ flexShrink: 0 }}
                onClick={() => setUserAction({ type: "add" })}
              >
                <IonIcon icon={addOutline} />
                New Customer
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Contact</th>
                  <th>Overdraft</th>
                  <th>Wallet</th>
                  <th>Branch</th>
                  <th>Login Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={10}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      Error fetching customers.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !users.length ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      There are no customers at the moment
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && users.length
                  ? users.map((user) => (
                      <tr key={user._id}>
                        <td>{user.Name}</td>
                        <td>{user.Company}</td>
                        <td>
                          <div>
                            <p>
                              <strong>Telephone:</strong> {user.Telephone}
                            </p>
                            {user.EmailAddress ? (
                              <p>
                                <strong>Email Address:</strong>{" "}
                                {user.EmailAddress}
                              </p>
                            ) : null}
                          </div>
                        </td>
                        <td>
                          {user.Overdraft ? "Active" : "Inactive"}
                          {user.Overdraft ? (
                            <> (&#8358; {roundDP(user.CreditLimit, 2)})</>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>&#8358; {roundDP(user.Wallet, 2)}</td>
                        <td>{user.Branch?.Name || "Sign Up"}</td>
                        <td>{user.LoginActive ? "ACTIVE" : "INACTIVE"}</td>
                        <td>
                          <div className="shipments-menu">
                            <div className="shipments-menu__main">
                              <IonIcon icon={ellipsisVerticalOutline} />
                            </div>
                            <div className="shipments-menu__block">
                              <div className="shipments-menu__block-main">
                                <div onClick={() => updateUser(user._id)}>
                                  <IonIcon icon={createOutline} /> Update User
                                </div>
                                {!user.LoginActive ? (
                                  <div
                                    onClick={() => {
                                      setUserAssignLogin(user._id);
                                      setConfirmAssignLogin(
                                        user.EmailAddress ? "email" : "no-email"
                                      );
                                    }}
                                  >
                                    <IonIcon icon={logInOutline} /> Assign Login
                                  </div>
                                ) : null}
                                <Link to={`/shipments?customer=${user._id}`}>
                                  <IonIcon icon={boatOutline} /> View Shipments
                                </Link>
                                {admin.RoleGroup === "SuperAdmin" ? (
                                  <>
                                    <Link
                                      to={`/transactions?customer=${user._id}`}
                                    >
                                      <IonIcon icon={receiptOutline} /> View
                                      Transactions
                                    </Link>
                                    <div
                                      onClick={() => setTopupWallet(user._id)}
                                    >
                                      <IonIcon icon={walletOutline} /> Topup
                                      Wallet
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div className="shipments-menu__block-top"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          {page !== 1 || users.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Users);
