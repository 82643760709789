import { MouseEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  trendingDownOutline,
  trendingUpOutline,
  wallet,
  receiptOutline,
  paperPlaneOutline,
  thumbsUpOutline,
  closeCircle,
  syncOutline,
  helpCircleOutline,
  readerOutline,
  addOutline,
  searchOutline,
  closeOutline,
  filterOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tBranch, tOverview, tUser } from "../../store/types/app.types";
import { tCarrier, tShipments } from "../../store/types/shipping.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useSelectUser from "../../hooks/useSelectUser/useSelectUser";

import Header from "../../components/Header/Header";
import ShipmentActions from "../../components/ShipmentActions/ShipmentActions";
import SearchBox from "../../components/SearchBox/SearchBox";
import Spinner from "../../Loaders/Spinner/Spinner";

import { compSelectHandler } from "../NewShipment/NewShipment";

import { assertNotNull, copyData, getDate, roundDP } from "../../utils/func";

import { DATE_RANGES } from "../../data";
import useAlert from "../../hooks/useAlert/useAlert";

const Dashboard = () => {
  const navigate = useNavigate();

  const { admin, accessToken } = useSelector((state: tRootState) => state.user);
  const {
    overview: cachedOverview,
    carriers,
    branches,
    shippingTypes,
  } = useSelector((state: tRootState) => state.cache);

  assertNotNull(admin);
  assertNotNull(cachedOverview);

  const [selectUser] = useSelectUser();

  const { fetchCarriers, fetchBranches, fetchOverview } = useData();

  const [user, setUser] = useState<tUser | null>(null);

  const [userSearch, setUserSearch] = useState("");
  const [userSearchFocus, setUserSearchFocus] = useState(false);

  const [dateRange, setDateRange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [carrier, setCarrier] = useState<tCarrier | null>(null);
  const [branch, setBranch] = useState<tBranch | null>(admin?.Branch || null);
  const [shippingType, setShippingType] = useState("");

  const [message, setMessage, clearMessage] = useAlert();

  const [showFilterModal, setShowFilterModal] = useState(false);

  const [overview, setOverview] = useState<tOverview>(cachedOverview);
  const [shipments, setShipments] = useState<tShipments>(overview.shipments);

  const [showSpinner, setShowSpinner] = useState(false);

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    if (dateRange === "CUSTOM" && (!startDate || !endDate))
      return setMessage(
        "warning",
        "Start date and end date is required for custom filtering"
      );

    setShowFilterModal(false);

    setShowSpinner(true);

    api_client({
      url: `/shipments/overview?1=1${
        dateRange && dateRange !== "CUSTOM" ? `&dateRange=${dateRange}` : ""
      }${dateRange === "CUSTOM" ? `&dateRange=${startDate}:${endDate}` : ""}${
        carrier ? `&carrier=${carrier._id}` : ""
      }${branch ? `&branch=${branch._id}` : ""}${
        user ? `&user=${user._id}` : ""
      }${shippingType ? `&shipmentType=${shippingType}` : ""}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
    })
      .then((res) => {
        setOverview(res.data.data);
      })
      .catch((err) => {
        // do nothing
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    setShipments(overview.shipments);
  }, [overview]);

  useEffect(() => {
    setUserSearch(user?.Name || "");
  }, [user]);

  useEffect(() => {
    clearMessage();
  }, [
    dateRange,
    startDate,
    endDate,
    carrier,
    branch,
    user,
    shippingType,
    clearMessage,
  ]);

  useEffect(() => {
    fetchCarriers();
    fetchBranches();
    fetchOverview().then((data) => {
      if (typeof data !== "string") setOverview(data as any);
    });
  }, [fetchCarriers, fetchBranches, fetchOverview]);

  return (
    <>
      {showSpinner ? <Spinner /> : null}
      {selectUser}

      <>
        <div
          className={cls("modal modal--xs", showFilterModal && "modal--open")}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Filter Overview</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setShowFilterModal(false)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>Pickup Range</label>
                <select
                  className="form-select"
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                >
                  <option value="">-- Select pickup range --</option>
                  {DATE_RANGES.map((range) => (
                    <option value={range.key} key={range.key}>
                      {range.value}
                    </option>
                  ))}
                </select>
              </div>
              {dateRange === "CUSTOM" ? (
                <div className="form-grid">
                  <div className="form-group">
                    <label>
                      Start Date <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      End Date <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-input"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
              ) : null}
              <div className="form-group">
                <label>Carrier</label>
                <select
                  className="form-select"
                  value={carrier!?._id || ""}
                  onChange={compSelectHandler<tCarrier>(carriers, setCarrier)}
                >
                  <option value="">-- Select carrier --</option>
                  {carriers.map((carr) => (
                    <option key={carr._id} value={carr._id}>
                      {carr.Name}
                    </option>
                  ))}
                </select>
              </div>
              {admin!.RoleGroup === "SuperAdmin" ? (
                <div className="form-group">
                  <label>Branch</label>
                  <select
                    className="form-select"
                    value={branch!?._id || ""}
                    onChange={compSelectHandler<tBranch>(branches, setBranch)}
                  >
                    <option value="">-- Select Branch --</option>
                    {branches.map((brnch) => (
                      <option key={brnch._id} value={brnch._id}>
                        {brnch.Name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              <div className="form-group">
                <label>Customer</label>
                <div
                  className={cls(
                    "input-group",
                    user && "input-group--disabled"
                  )}
                >
                  <input
                    type="text"
                    placeholder="Select Customer"
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                    disabled={!!user}
                    onFocus={(e) => {
                      setUserSearchFocus(true);
                    }}
                    onBlur={() => {
                      setUserSearchFocus(false);
                    }}
                  />
                  {user ? (
                    <span
                      className="input-group__clear"
                      onClick={() => setUser(null)}
                    >
                      <IonIcon icon={closeOutline} />
                    </span>
                  ) : null}
                  <button>
                    <IonIcon icon={searchOutline} />
                  </button>
                </div>
                <SearchBox
                  focus={userSearchFocus}
                  url="/users?page=1&division=8&search={{search}}"
                  search={userSearch}
                  displayKey="Name"
                  clickHandler={setUser}
                />
              </div>
              <div className="form-group">
                <label>Shipment Type</label>
                <select
                  className="form-select"
                  value={shippingType}
                  onChange={(e) => setShippingType(e.target.value)}
                >
                  <option value="">-- Select Shipment Type --</option>
                  {shippingTypes.map((shippType) => (
                    <option value={shippType} key={shippType}>
                      {shippType}
                    </option>
                  ))}
                </select>
              </div>
              {message}
              <button className="button" onClick={handleSearch}>
                <IonIcon icon={filterOutline} /> Filter Overview
              </button>
            </div>
          </div>
        </div>
        {showFilterModal ? (
          <div
            className="overlay"
            onClick={() => setShowFilterModal(false)}
          ></div>
        ) : null}
      </>

      <Header />
      <section
        className={cls(
          "dashboard-header",
          admin.RoleGroup === "Admin" ? "dashboard-header--diff" : ""
        )}
      >
        <div className="dashboard-header__container container">
          <div className="dashboard-header__main">
            <h3 className="dashboard-header__heading">
              Welcome Back, {admin.Name}
            </h3>
            <p className="dashboard-header__text">
              This is your Overview Report
            </p>
          </div>
          <div>
            <button className="btn" onClick={(e) => setShowFilterModal(true)}>
              <IonIcon icon={filterOutline} />
              Filter Overview
            </button>
          </div>
        </div>
      </section>
      <main className="main">
        <div className="container">
          <div className="dsection">
            {admin.RoleGroup === "SuperAdmin" ? (
              <div className="dcard-main-grid">
                <div className="dcard dcard--success">
                  <div className="dcard__main">
                    <p className="dcard__key">Total Income</p>
                    <p className="dcard__value">
                      &#8358;{roundDP(overview.chargeData.incomeCharge, 2)}
                    </p>
                  </div>
                  <div className="dcard__icon-block">
                    <IonIcon icon={trendingDownOutline} />
                  </div>
                </div>
                <div className="dcard dcard--danger">
                  <div className="dcard__main">
                    <p className="dcard__key">Total Expenses</p>
                    <p className="dcard__value">
                      &#8358;{roundDP(overview.chargeData.expenseCharge, 2)}
                    </p>
                  </div>
                  <div className="dcard__icon-block">
                    <IonIcon icon={trendingUpOutline} />
                  </div>
                </div>
                <div className="dcard dcard--info">
                  <div className="dcard__main">
                    <p className="dcard__key">Net Profit</p>
                    <p className="dcard__value">
                      &#8358;{roundDP(overview.chargeData.netProfit, 2)}
                    </p>
                  </div>
                  <div className="dcard__icon-block">
                    <IonIcon icon={wallet} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="dcard-main-grid"></div>
            )}
            <div className="info-cards">
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.CREATED}
                  </p>
                  <p className="dcard__key text-uppercase">Created</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={helpCircleOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData["PRE-TRANSIT"]}
                  </p>
                  <p className="dcard__key text-uppercase">Pre-Transit</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={receiptOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.TRANSIT}
                  </p>
                  <p className="dcard__key text-uppercase">Transit</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={paperPlaneOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.DELIVERED}
                  </p>
                  <p className="dcard__key text-uppercase">Delivered</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={thumbsUpOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.FAILED}
                  </p>
                  <p className="dcard__key text-uppercase">Failed</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={closeCircle} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.CANCELED}
                  </p>
                  <p className="dcard__key text-uppercase">Canceled</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={closeCircle} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {overview.analyticsData.RETURNED}
                  </p>
                  <p className="dcard__key text-uppercase">Returned</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={syncOutline} />
                </div>
              </div>
              <div className="dcard">
                <div className="dcard__main">
                  <p className="dcard__value">
                    {Object.values(overview.analyticsData).reduce(
                      (a, b) => a + b,
                      0
                    )}
                  </p>
                  <p className="dcard__key text-uppercase">Total</p>
                </div>
                <div className="dcard__icon-block">
                  <IonIcon icon={readerOutline} />
                </div>
              </div>
            </div>
            <section>
              <div className="dheader">
                <h3 className="dheading">Recent Shipments</h3>
                <button
                  className="btn btn--primary"
                  onClick={() => navigate("/new-shipment")}
                >
                  <IonIcon icon={addOutline} />
                  New Shipment
                </button>
              </div>
              <div className="table-responsive shipments-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Customer</th>
                      <th>Description</th>
                      {/* <th>Pickup</th> */}
                      <th>Delivery</th>
                      <th>Pickup Date</th>
                      <th>Carrier</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!shipments.length ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No shipments
                        </td>
                      </tr>
                    ) : null}
                    {shipments.map((shipment) => (
                      <tr key={shipment._id}>
                        <td>
                          <span onClick={() => copyData(shipment._id)}>
                            #{shipment._id.slice(shipment._id.length - 6)}
                          </span>
                        </td>
                        <td>{shipment.User.Name}</td>
                        <td>{shipment.Description}</td>
                        {/* <td>
                          {shipment.Shipper.Address}, {shipment.Shipper.State},{" "}
                          {shipment.Shipper.Country.Name}
                        </td> */}
                        <td>
                          <div>
                            <p>
                              <strong>Name:</strong> {shipment.Cosignee.Name}
                            </p>
                            <p>
                              <strong>Telephone:</strong>{" "}
                              {shipment.Cosignee.Telephone}
                            </p>
                            <p>
                              <strong>Address:</strong>{" "}
                              {shipment.Cosignee.Address},{" "}
                              {shipment.Cosignee.State},{" "}
                              {shipment.Cosignee.Country.Name}
                            </p>
                          </div>
                        </td>
                        <td>{getDate(shipment.PickupDate!)}</td>
                        <td>
                          {shipment.Carrier.Name}
                          {shipment.Rider ? (
                            <>
                              <br />
                              (Rider: {shipment.Rider.FirstName}{" "}
                              {shipment.Rider.LastName})
                            </>
                          ) : null}
                        </td>
                        <td title={shipment.LastActivity}>
                          {shipment.Status}
                          <br />({shipment.LastActivity.slice(0, 30)}
                          {shipment.LastActivity.length > 30 ? "..." : ""})
                        </td>
                        <td>
                          <ShipmentActions
                            shipment={shipment}
                            setShipments={setShipments}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="shipments">
                {shipments.map((shipment) => (
                  <div className="shipment" key={shipment._id}>
                    <div className="shipment__header">
                      <p>
                        <strong>Shipment:</strong> #
                        {shipment._id.slice(shipment._id.length - 6)}
                      </p>
                      <div className="shipment__right">
                        <span title={shipment.LastActivity}>
                          {shipment.Status}
                        </span>
                        <ShipmentActions
                          setShipments={setShipments}
                          shipment={shipment}
                        />
                      </div>
                    </div>
                    <div className="shipment__body">
                      <div className="mb-small">
                        <p>
                          <strong>Customer:</strong> {shipment.User.Name}
                        </p>
                      </div>
                      <div className="mb-small">
                        <p>
                          <strong>Description:</strong> {shipment.Description}
                        </p>
                        {shipment.PickupDate ? (
                          <p>
                            <strong>Pickup Date:</strong>{" "}
                            {getDate(shipment.PickupDate)}
                          </p>
                        ) : null}
                        <p>
                          <strong>Carrier:</strong> {shipment.Carrier.Name}{" "}
                          {shipment.Rider ? (
                            <>
                              (Rider: {shipment.Rider.FirstName}{" "}
                              {shipment.Rider.LastName})
                            </>
                          ) : null}
                        </p>
                      </div>
                      <div>
                        <p>
                          <strong>Name:</strong> {shipment.Cosignee.Name}
                        </p>
                        <p>
                          <strong>Telephone:</strong>{" "}
                          {shipment.Cosignee.Telephone}
                        </p>
                        <p>
                          <strong>Address:</strong> {shipment.Cosignee.Address}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Dashboard);
