import { FormEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  analyticsOutline,
  bicycleOutline,
  boatOutline,
  closeCircleOutline,
  closeOutline,
  createOutline,
  documentTextOutline,
  ellipsisVertical,
  linkOutline,
  newspaperOutline,
  pricetagOutline,
  syncOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tShipment, tShipments } from "../../store/types/shipping.types";
import { Entries } from "../../utils/utils.types";

import { tRoute } from "../SelectRider/SelectRider";
import useSelectRider from "../../hooks/useSelectRider/useSelectRider";

import Spinner from "../../Loaders/Spinner/Spinner";
import useAlert from "../../hooks/useAlert/useAlert";

import { SHIPMENTS_STATUS } from "../../data";

// optimized solution for time between setting route and opening select rider modal
const ShipmentActions = ({
  shipment,
  setShipments,
  reloadShipments,
}: {
  shipment: tShipment;
  setShipments: React.Dispatch<React.SetStateAction<tShipments>>;
  reloadShipments?: () => void;
}) => {
  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [route, setRoute] = useState<tRoute>(null);
  const [carrier, setCarrier] = useState("");

  const [selectRider, openSelectRider] = useSelectRider({
    route: route,
    carrier,
  });

  const [showSpinner, setShowSpinner] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const [confirmBooking, setConfirmBooking] = useState("");

  const [updateStatus, setUpdateStatus] = useState("");
  const [confirmCancelShipment, setConfirmCancelShipment] = useState("");

  const [status, setStatus] = useState("");
  const [activity, setActivity] = useState("");
  const [customActivity, setCustomActivity] = useState("");
  const [reason, setReason] = useState("");
  const [deliveryCode, setDeliveryCode] = useState("");

  const updateStatusBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert();

  const updateShipmentStatus = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!status || !activity)
      return setMessage("warning", "Fill in all fields");

    if (activity === "custom" && !customActivity)
      return setMessage("warning", "Fill in all fields");

    if (status === "CANCELED" && !reason)
      return setMessage("warning", "Fill in all fields");

    // if (status === "DELIVERED" && !deliveryCode)
    //   return setMessage("warning", "Fill in all fields");

    const btnHTML = updateStatusBtnRef.current.innerHTML;

    updateStatusBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    updateStatusBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: `/shipments/${updateStatus}`,
      method: "PATCH",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Status: status,
        Activity: activity === "custom" ? customActivity : activity,
        Reason: reason,
        DeliveryCode: deliveryCode,
      },
    })
      .then((res) => {
        setShipments((shipmts) =>
          shipmts.map((shipment) =>
            shipment._id === res.data.data._id ? res.data.data : shipment
          )
        );

        const statusObj =
          status !== "CANCELED"
            ? {
                title: "Status updated!",
                message: "Shipment status has been successfully updated",
              }
            : {
                title: "Status canceled!",
                message: "Shipment canceled successfully",
              };

        setUpdateStatus("");

        setSuccess(statusObj);

        if (status === "CANCELED" && reloadShipments) reloadShipments();
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage(
            "error",
            "An error occured while trying to update status. Try again"
          );
        }
      })
      .finally(() => {
        updateStatusBtnRef.current.removeAttribute("disabled");
        updateStatusBtnRef.current.innerHTML = btnHTML;
      });
  };

  const assignRider = (shipmentId: string, rider: string) => {
    setShowSpinner(true);

    api_client({
      url: `/shipments/rider/${shipmentId}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        Rider: rider,
      },
    })
      .then((res) => {
        setShipments((shipmts) =>
          shipmts.map((shipment) =>
            shipment._id === res.data.data._id ? res.data.data : shipment
          )
        );
        setSuccess({
          title: "Rider Assigned!",
          message: "Rider has been successfully assigned to this shipment",
        });
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setError(err.response.data.message);
        } else {
          setError("An error occured while trying to assign rider. Try again");
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const bookShipment = (shipmentId: string) => {
    setShowSpinner(true);

    api_client({
      url: `/shipments/book/${shipmentId}`,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        setShipments((shipmts) =>
          shipmts.map((shipment) =>
            shipment._id === res.data.data._id ? res.data.data : shipment
          )
        );
        setSuccess({
          title: "Shipment Booked!",
          message: "This shipment has been booked successfully",
        });
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setError(err.response.data.message);
        } else {
          setError("An error occured while trying to book shipment. Try again");
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  useEffect(() => {
    clearMessage();
  }, [status, activity, customActivity, clearMessage]);

  useEffect(() => {
    if (updateStatus) return;

    setStatus("");
    setActivity("");
    setCustomActivity("");
    setReason("");
    setDeliveryCode("");
  }, [updateStatus]);

  useEffect(() => {
    if (status === "DELIVERED") setActivity("Package has been delivered");
    if (status === "TRANSIT") setActivity("Package is out for delivery");
  }, [status]);

  return (
    <>
      {selectRider}
      {showSpinner ? <Spinner /> : null}
      {confirmBooking ? (
        <div>
          <div className="success-modal">
            <h3>Confirmation</h3>
            <p className="text-center">
              Are you sure you want to proceed with booking this shipment. Make
              sure to confirm rider you cannot re-assign rider after this
              operation
            </p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  setConfirmBooking("");
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn--success"
                onClick={() => {
                  bookShipment(confirmBooking);
                  setConfirmBooking("");
                }}
              >
                Continue
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setConfirmBooking("")}></div>
        </div>
      ) : null}
      {confirmCancelShipment ? (
        <div>
          <div className="success-modal">
            <h3>Confirmation</h3>
            <p className="text-center">
              Are you sure you want to cance this shipment
            </p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  setConfirmCancelShipment("");
                }}
              >
                Close
              </button>
              <button
                className="btn btn--primary"
                onClick={() => {
                  setConfirmCancelShipment("");
                  setStatus("CANCELED");
                  setActivity("Shipment Canceled");
                  setUpdateStatus(confirmCancelShipment);
                }}
              >
                Continue
              </button>
            </div>
          </div>
          <div
            className="overlay"
            onClick={() => setConfirmCancelShipment("")}
          ></div>
        </div>
      ) : null}
      {error ? (
        <div>
          <div className="success-modal">
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            <p className="text-center">{error}</p>
          </div>
          <div className="overlay" onClick={() => setError("")}></div>
        </div>
      ) : null}
      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p>{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}
      <div>
        <form
          className={cls("modal modal--sm", updateStatus && "modal--open")}
          onSubmit={updateShipmentStatus}
        >
          <div className="modal__header">
            <h3 className="modal__heading">
              {status === "CANCELED" ? "Cancel Shipment" : "Update Status"}
            </h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => setUpdateStatus("")}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              {status === "CANCELED" ? (
                <div className="form-group">
                  <label>
                    Reason <span>*</span>
                  </label>
                  <textarea
                    rows={5}
                    className="form-input"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Enter reason for canceling"
                  ></textarea>
                </div>
              ) : (
                <>
                  <div className="form-group">
                    <label>
                      Status <span>*</span>
                    </label>
                    <select
                      className="form-select"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">-- Select Status --</option>
                      {Object.keys(SHIPMENTS_STATUS).map((status) => (
                        <option key={status}>{status}</option>
                      ))}
                    </select>
                  </div>
                  {status && status !== "DELIVERED" ? (
                    <div className="form-group">
                      <label>
                        Activity <span>*</span>
                      </label>
                      <select
                        className="form-select"
                        value={activity}
                        onChange={(e) => setActivity(e.target.value)}
                      >
                        <option value="">-- Select Activity --</option>
                        <option>custom</option>
                        {(
                          Object.entries(
                            (SHIPMENTS_STATUS as any)[status]
                          ) as Entries<{
                            [key: string]: string;
                          }>
                        ).map(([key, value], i) => (
                          <option key={i} value={value}>
                            {key.toLowerCase()} - {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  {activity === "custom" ? (
                    <div className="form-group">
                      <label>
                        Custom Activity <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Enter custom activity"
                        value={customActivity}
                        onChange={(e) => setCustomActivity(e.target.value)}
                      />
                    </div>
                  ) : null}
                  {status === "DELIVERED" ? (
                    <div className="form-group">
                      <label>Delivery Code</label>
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Enter delivery code"
                        value={deliveryCode}
                        onChange={(e) => setDeliveryCode(e.target.value)}
                      />
                    </div>
                  ) : null}
                </>
              )}
              {message}
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button
                className="btn btn--primary"
                type="submit"
                ref={updateStatusBtnRef}
              >
                {status === "CANCELED" ? "Cancel Shipment" : "Update Status"}
              </button>
            </div>
          </div>
        </form>
        {updateStatus ? (
          <div className="overlay" onClick={() => setUpdateStatus("")}></div>
        ) : null}
      </div>
      <div className="flex-center">
        <div className="shipments-menu">
          <div className="shipments-menu__main">
            <IonIcon icon={ellipsisVertical} />
          </div>
          <div className="shipments-menu__block">
            <div className="shipments-menu__block-main">
              {!shipment.Booked &&
              ["CREATED", "PRE-TRANSIT"].includes(shipment.Status) ? (
                <>
                  <div
                    onClick={() => navigate(`/edit-shipment/${shipment._id}`)}
                  >
                    <IonIcon icon={createOutline} /> Edit Shipment
                  </div>
                  <div onClick={() => setConfirmBooking(shipment._id)}>
                    <IonIcon icon={boatOutline} /> Book on{" "}
                    {shipment.Carrier.Name}
                  </div>
                </>
              ) : null}
              {shipment.Booked ? (
                <a
                  href={`${process.env.REACT_APP_TRACKING_BASE_URL}/${shipment.TrackingNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IonIcon icon={linkOutline} /> Track on DMX
                </a>
              ) : null}
              {shipment.DHLTrackingNumber ? (
                <a
                  href={`https://mydhl.express.dhl/ng/en/tracking.html#/results?id=${shipment.DHLTrackingNumber}&brand=DHL`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IonIcon icon={analyticsOutline} /> Track on DHL
                </a>
              ) : null}
              {shipment.Label ? (
                <a href={shipment.LabelPath} target="_blank" rel="noreferrer">
                  <IonIcon icon={pricetagOutline} /> View / Print Label
                </a>
              ) : null}
              <a
                href={`${process.env.PUBLIC_URL}/waybill/${shipment._id}`}
                target="_blank"
                rel="noreferrer"
              >
                <IonIcon icon={documentTextOutline} /> View / Print Waybill
              </a>
              {shipment.Invoice ? (
                <a
                  href={`${process.env.PUBLIC_URL}/invoice/${shipment.Invoice}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IonIcon icon={newspaperOutline} /> View Invoice
                </a>
              ) : null}
              {shipment.Carrier.Type === "Internal" && !shipment.Booked ? (
                <div
                  onClick={() => {
                    setRoute({
                      routeType: "State",
                      country: shipment.Shipper.Country._id,
                      route: [
                        {
                          _id: shipment.Shipper.State,
                          name: shipment.Shipper.State,
                        },
                        {
                          _id: shipment.Cosignee.State,
                          name: shipment.Cosignee.State,
                        },
                      ],
                    });
                    setCarrier(shipment.Carrier._id);
                    openSelectRider((rider) => {
                      if (rider && typeof rider !== "function")
                        assignRider(shipment._id, rider._id);
                    });
                  }}
                >
                  <IonIcon icon={bicycleOutline} />{" "}
                  {shipment.Rider ? "Re-assign" : "Assign"} Rider
                </div>
              ) : null}
              {shipment.Carrier.Code !== "DHL" &&
              shipment.Booked &&
              ["TRANSIT", "PRE-TRANSIT", "RETURNED"].includes(
                shipment.Status
              ) ? (
                <div onClick={() => setUpdateStatus(shipment._id)}>
                  <IonIcon icon={syncOutline} /> Update Status
                </div>
              ) : null}
              {shipment.Status === "PRE-TRANSIT" &&
              !(shipment.Carrier.Type === "External" && shipment.Booked) ? (
                <div onClick={() => setConfirmCancelShipment(shipment._id)}>
                  <IonIcon icon={closeCircleOutline} /> Cancel Shipment
                </div>
              ) : null}
            </div>
            <div className="shipments-menu__block-top"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipmentActions;
