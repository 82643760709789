import { useState, useRef, useEffect, FormEvent } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  boatOutline,
  checkmark,
  chevronDownOutline,
  closeOutline,
  createOutline,
  ellipsisVerticalOutline,
  eyeOffOutline,
  eyeOutline,
  stopCircleOutline,
  walletOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCountry, tRiders } from "../../store/types/app.types";
import { tCarrier } from "../../store/types/shipping.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useAlert from "../../hooks/useAlert/useAlert";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";
import useData from "../../hooks/useData/useData";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";
import Spinner from "../../Loaders/Spinner/Spinner";
import { compSelectHandler } from "../NewShipment/NewShipment";

import {
  assertNotNull,
  copyData,
  getInputDate,
  getSkip,
  isNumber,
  roundDP,
} from "../../utils/func";

const DEFAULT_AVATAR =
  "https://alley-whatsapp-clone.netlify.app/assets/img/user-avatar.svg";

const Riders = () => {
  const { accessToken, admin } = useSelector((state: tRootState) => state.user);

  assertNotNull(admin);

  const { countries, countriesSelectData, transportTypes, carriers } =
    useSelector((state: tRootState) => state.cache);

  const { fetchCountries, fetchTransportTypes, fetchCarriers } = useData();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(1);
  const [division] = useState<number>(20);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [riders, setRiders] = useState<tRiders>([]);

  // Add User
  const [riderAction, setRiderAction] = useState<{
    type: "add" | "update";
    riderId?: string;
  } | null>(null);

  const [step, setStep] = useState<number>(1);

  const [personalInfoDone, setPersonalInfoDone] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [defaultAvatar, setDefaultAvatar] = useState(DEFAULT_AVATAR);

  const [picture, setPicture] = useState<File | null>(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bvn, setBVN] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [
    telephoneCountrySelectBox,
    selectedTelephoneCountryId,
    openTelephoneCountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [telephoneCountry, setTelephoneCountry] = useState<tCountry | null>(
    null
  );
  const [telephone, setTelephone] = useState<number | "">("");
  const [address, setAddress] = useState("");

  const [pinfoVerified, setPInfoVerified] = useState<"Yes" | "No">("No");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [carrier, setCarrier] = useState<tCarrier | null>(null);

  const [showPassword, setShowPassword] = useState(false);

  const [transportInfoDone, setTransportInfoDone] = useState(false);

  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [vin, setVIN] = useState("");
  const [color, setColor] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [licenseExpiryDate, setLicenseExpiryDate] = useState("");
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState("");
  const [insuranceCompany, setInsuranceCompany] = useState("");

  const [tinfoVerified, setTInfoVerified] = useState<"Yes" | "No">("No");

  const addRiderBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert();
  // End Add User

  // Topup Wallet
  const [payout, setPayout] = useState("");

  const [amount, setAmount] = useState<number | string>("");

  const [pyMessage, pySetMessage, pyClearMessage] = useAlert();

  const payoutBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  // End Topup Wallet

  const [showSpinner, setShowSpinner] = useState(false);

  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const payoutAction = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!amount) return pySetMessage("warning", "Amount is required");

    payoutBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    payoutBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: `/riders/${payout}/payout`,
      method: "POST",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Amount: amount,
      },
    })
      .then((res) => {
        setPayout("");
        clearPayoutData();

        setSuccess({
          title: "Payout Successful!",
          message: "Payout for rider successfully recorded",
        });

        setRiders((riders) =>
          riders.map((rider) => (rider._id === payout ? res.data.data : rider))
        );
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          pySetMessage("warning", err.response.data.message);
        } else {
          pySetMessage("error", err.message);
        }
      })
      .finally(() => {
        payoutBtnRef.current.removeAttribute("disabled");
        payoutBtnRef.current.innerHTML = "Add";
      });
  };

  const clearPayoutData = () => {
    setAmount("");
    pyClearMessage();
  };

  const clearRiderData = () => {
    setDefaultAvatar(DEFAULT_AVATAR);
    setPicture(null);

    setFirstName("");
    setLastName("");
    setAddress("");

    setBVN("");
    setEmailAddress("");
    setTelephoneCountry(null);
    setTelephone("");

    setUsername("");
    setPassword("");
    setShowPassword(false);

    setType("");
    setDescription("");
    setVIN("");
    setColor("");
    setLicensePlate("");
    setLicenseExpiryDate("");
    setInsurancePolicyNumber("");
    setInsuranceCompany("");

    setStep(1);

    clearMessage();
  };

  const addUpdateRider = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!riderAction) return;

    if (!personalInfoDone || !transportInfoDone)
      return setMessage("warning", "Fill in all required fields");

    if (riderAction.type === "add" && !picture)
      return setMessage("warning", "You need to upload your picture");

    if (picture) {
      if (picture.size > 2000000)
        return setMessage("warning", "File size must not be greater than 2MB");

      const pictureSplit = picture.name.toLowerCase().split(".");

      if (
        !["jpg", "png", "jpeg"].includes(
          pictureSplit[pictureSplit.length - 1]
        ) ||
        !picture.type.startsWith("image/")
      )
        return setMessage("warning", "Image must be .png, .jpg or .jpeg");
    }

    if (telephone.toString().length !== 10)
      return setMessage(
        "warning",
        "Telephone number should be 10 characters long"
      );

    const btnHTML = addRiderBtnRef.current.innerHTML;

    addRiderBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    addRiderBtnRef.current.setAttribute("disabled", "disabled");

    let data: any;
    let contentType: any;

    if (riderAction.type === "update") {
      contentType = "application/json";
      data = {
        Carrier: carrier?._id,
        Verified: pinfoVerified,
        TransportVerified: tinfoVerified,
      };
    } else {
      contentType = "multipart/form-data";

      data = new FormData();

      data.append("FirstName", firstName);
      data.append("LastName", lastName);
      data.append("Address", address);

      data.append("BVN", bvn);
      data.append("EmailAddress", emailAddress);
      data.append("TelephoneCountry", telephoneCountry?._id);
      data.append("Telephone", telephone);

      data.append("Type", type);
      data.append("Description", description);
      data.append("VIN", vin);
      data.append("Color", color);
      data.append("LicensePlate", licensePlate);
      data.append("LicenseExpiryDate", licenseExpiryDate);
      data.append("InsurancePolicyNumber", insurancePolicyNumber);
      data.append("InsuranceCompany", insuranceCompany);

      data.append("Username", username);
      data.append("Password", password);

      data.append("Carrier", carrier?._id);
      data.append("Verified", pinfoVerified);
      data.append("TransportVerified", tinfoVerified);

      data.append("file", picture);
    }

    api_client({
      url:
        riderAction.type === "add"
          ? "/riders"
          : `/riders/${riderAction.riderId}`,
      method: riderAction.type === "add" ? "POST" : "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": contentType,
      },
      data,
    })
      .then((res) => {
        setRiderAction(null);
        clearRiderData();

        setSuccess(
          riderAction.type === "add"
            ? {
                title: "Rider added!",
                message: "Rider added successfully",
              }
            : { title: "Rider updated!", message: "Rider updated successfully" }
        );

        if (riderAction.type === "add") {
          setSearch("");
          setPage(1);
          setReload((rl) => !rl);
        } else {
          setRiders((riders) =>
            riders.map((rider) =>
              rider._id === res.data.data._id ? res.data.data : rider
            )
          );
        }
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        addRiderBtnRef.current.removeAttribute("disabled");
        addRiderBtnRef.current.innerHTML = btnHTML;
      });
  };

  const updateRider = (riderId: string) => {
    setShowSpinner(true);

    api_client({
      url: `/riders/${riderId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        const rider: any = res.data.data;

        setDefaultAvatar(rider.ProfilePicturePath || DEFAULT_AVATAR);
        setPicture(null);

        setFirstName(rider.FirstName);
        setLastName(rider.LastName);
        setAddress(rider.Address);
        setBVN(rider.BVN);
        setEmailAddress(rider.EmailAddress);
        setTelephoneCountry(rider.TelephoneCountry);
        setTelephone(rider.Telephone.slice(rider.Telephone.length - 10));

        setPInfoVerified(rider.Verified ? "Yes" : "No");
        setCarrier(rider.Carrier || null);

        const transport: any = rider.ActiveTransport;

        setType(transport.Type._id);
        setDescription(transport.Description);
        setVIN(transport.VIN);
        setColor(transport.Color);
        setLicensePlate(transport.LicensePlate);
        setLicenseExpiryDate(getInputDate(transport.LicenseExpiryDate));
        setInsurancePolicyNumber(transport.InsurancePolicyNumber);
        setInsuranceCompany(transport.InsuranceCompany);

        setTInfoVerified(transport.Verified ? "Yes" : "No");

        setRiderAction({ type: "update", riderId: rider._id });
      })
      .catch((err) => {})
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const previous = () => {
    if (step === 1) return;

    setStep(step - 1);
  };

  const next = () => {
    if (step === 2) return;

    const newStep = step + 1;

    if (newStep === 2 && !personalInfoDone)
      return setMessage("warning", "Fill in all required fields");

    setStep(newStep);
  };

  useEffect(() => {
    pyClearMessage();
  }, [amount, pyClearMessage]);

  useEffect(() => {
    clearMessage();
  }, [
    firstName,
    lastName,
    address,
    bvn,
    telephoneCountry,
    telephone,
    emailAddress,
    type,
    description,
    vin,
    color,
    licensePlate,
    licenseExpiryDate,
    insurancePolicyNumber,
    insuranceCompany,
    picture,
    username,
    password,
    pinfoVerified,
    tinfoVerified,
    clearMessage,
  ]);

  useEffect(() => {
    setPersonalInfoDone(
      !!(
        firstName &&
        lastName &&
        address &&
        bvn &&
        telephoneCountry &&
        telephone &&
        emailAddress &&
        (!riderAction ||
          riderAction.type === "update" ||
          (username && password && picture))
      )
    );
  }, [
    firstName,
    lastName,
    address,
    bvn,
    telephoneCountry,
    telephone,
    emailAddress,
    username,
    password,
    riderAction,
    picture,
  ]);

  useEffect(() => {
    setTransportInfoDone(
      !!(
        type &&
        description &&
        vin &&
        color &&
        licensePlate &&
        licenseExpiryDate &&
        insurancePolicyNumber &&
        insuranceCompany
      )
    );
  }, [
    type,
    description,
    vin,
    color,
    licensePlate,
    licenseExpiryDate,
    insurancePolicyNumber,
    insuranceCompany,
  ]);

  useEffect(() => {
    if (!selectedTelephoneCountryId) return setTelephoneCountry(null);

    setTelephoneCountry(
      countries.find((ct) => ct._id === selectedTelephoneCountryId)!
    );
  }, [selectedTelephoneCountryId, countries]);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url: `/riders?page=${page}&division=${division}&search=${search}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setRiders(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [search, page, division, accessToken, reload]);

  useEffect(() => {
    fetchCountries();
    fetchTransportTypes();
    fetchCarriers();
  }, [fetchCountries, fetchTransportTypes, fetchCarriers]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      {telephoneCountrySelectBox}

      {showSpinner ? <Spinner /> : null}

      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p>{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}

      <div>
        <form
          className={cls(
            "modal modal--md",
            riderAction && !telephoneCountrySelectBox && "modal--open"
          )}
          onSubmit={addUpdateRider}
        >
          <div className="modal__header">
            <h3 className="modal__heading">
              {riderAction?.type === "update" ? "Update" : "Add New"} Rider
            </h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => {
                  setRiderAction(null);
                  clearRiderData();
                }}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-steps mb-medium">
              <div className="form-steps__step" onClick={() => setStep(1)}>
                <span
                  className={cls(
                    "form-steps__step-number",
                    personalInfoDone && "active"
                  )}
                >
                  {personalInfoDone ? <IonIcon icon={checkmark} /> : 1}
                </span>
                Personal Info
              </div>
              <div
                className={cls("form-steps__divider", step > 1 && "active")}
              ></div>
              <div
                className="form-steps__step"
                onClick={() => (personalInfoDone ? setStep(2) : null)}
              >
                <span
                  className={cls(
                    "form-steps__step-number",
                    transportInfoDone && "active"
                  )}
                >
                  {transportInfoDone ? <IonIcon icon={checkmark} /> : 2}
                </span>
                Transport Info
              </div>
            </div>
            <div className="form-flex">
              {message}
              <div className={cls("form-grid", step !== 1 && "hidden")}>
                <div className="grid-full text-center">
                  {picture ? (
                    <img
                      src={URL.createObjectURL(picture)}
                      alt=""
                      className="auth__img"
                    />
                  ) : (
                    <img src={defaultAvatar} alt="" className="auth__img" />
                  )}
                  <div className="simple-actions">
                    {picture ? (
                      <span
                        onClick={() => {
                          if (fileInputRef.current)
                            fileInputRef.current.value = "";
                          setPicture(null);
                        }}
                        className="text-danger"
                      >
                        Clear Image
                      </span>
                    ) : null}
                    <span
                      className="text-info"
                      onClick={() => {
                        if (!fileInputRef.current) return;
                        fileInputRef.current.value = "";
                        fileInputRef.current.click();
                      }}
                    >
                      {picture ? "Change" : "Select"} Image
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    BVN <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter BVN"
                    value={bvn}
                    onChange={(e) => setBVN(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Email address <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Name"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Phone <span>*</span>
                  </label>
                  <div className={"input-group"}>
                    <button
                      onClick={openTelephoneCountrySelectBox}
                      type="button"
                    >
                      {telephoneCountry
                        ? telephoneCountry.TelephoneCode
                        : "Select"}{" "}
                      <IonIcon icon={chevronDownOutline} />
                    </button>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      value={telephone}
                      onChange={(e) =>
                        e.target.value
                          ? isNumber(e.target.value)
                            ? setTelephone(+e.target.value)
                            : null
                          : setTelephone("")
                      }
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Address <span>*</span>
                  </label>
                  <input
                    className="form-input"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                {riderAction?.type === "add" ? (
                  <>
                    <div className="form-group">
                      <label>
                        Username <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Enter Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Password <span>*</span>
                      </label>
                      <div className="form-input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword((sp) => !sp)}
                        >
                          <IonIcon
                            icon={showPassword ? eyeOffOutline : eyeOutline}
                          />
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="form-group">
                  <label>
                    Verified <span>*</span>
                  </label>
                  <select
                    className="form-select"
                    value={pinfoVerified}
                    onChange={(e) => setPInfoVerified(e.target.value as any)}
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Carriers</label>
                  <select
                    className="form-select"
                    value={carrier!?._id || ""}
                    onChange={compSelectHandler<tCarrier>(carriers, setCarrier)}
                  >
                    <option value="">-- Assign carrier --</option>
                    {carriers
                      .filter((carr) => carr.Type === "Internal")
                      .map((carr) => (
                        <option key={carr._id} value={carr._id}>
                          {carr.Name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className={cls("form-grid", step !== 2 && "hidden")}>
                <div className="form-group">
                  <label>
                    Type <span>*</span>
                  </label>
                  <select
                    className="form-select"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="">-- Select Vehicle Type --</option>
                    {transportTypes.map((transportType) => (
                      <option key={transportType._id} value={transportType._id}>
                        {transportType.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    Description <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    VIN <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter vin"
                    value={vin}
                    onChange={(e) => setVIN(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Color <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    License Plate Number <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter license plate number"
                    value={licensePlate}
                    onChange={(e) => setLicensePlate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    License Expiry Date <span>*</span>
                  </label>
                  <input
                    type="date"
                    className="form-input"
                    placeholder="Enter color"
                    value={licenseExpiryDate}
                    onChange={(e) => setLicenseExpiryDate(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Insurance Policy Number <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter insurance policy number"
                    value={insurancePolicyNumber}
                    onChange={(e) => setInsurancePolicyNumber(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Insurance Company <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter insurance company"
                    value={insuranceCompany}
                    onChange={(e) => setInsuranceCompany(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Verified <span>*</span>
                  </label>
                  <select
                    className="form-select"
                    value={tinfoVerified}
                    onChange={(e) => setTInfoVerified(e.target.value as any)}
                  >
                    <option>No</option>
                    <option>Yes</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <div>
              {step > 1 ? (
                <button className="btn" type="button" onClick={previous}>
                  Previous
                </button>
              ) : null}
            </div>
            <div>
              {step === 2 ? (
                <button
                  className={cls("btn", transportInfoDone && "btn--primary")}
                  type="submit"
                  ref={addRiderBtnRef}
                >
                  {riderAction?.type === "update" ? "Update" : "Add"} Rider
                </button>
              ) : null}
              {step < 2 ? (
                <button
                  className={cls(
                    "btn",
                    ((step === 1 && personalInfoDone) ||
                      (step === 2 && transportInfoDone)) &&
                      "btn--primary"
                  )}
                  type="button"
                  onClick={next}
                >
                  Next
                </button>
              ) : null}
            </div>
          </div>
        </form>
        {riderAction && !telephoneCountrySelectBox ? (
          <div
            className="overlay"
            onClick={() => {
              setRiderAction(null);
              clearRiderData();
            }}
          ></div>
        ) : null}
      </div>

      <div>
        <form
          className={cls("modal modal--sm", payout && "modal--open")}
          onSubmit={payoutAction}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Add Payout</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => {
                  setPayout("");
                  clearPayoutData();
                }}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              <div className="form-group">
                <label>
                  Amount <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <button>NGN</button>
                  <input
                    type="text"
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) =>
                      e.target.value
                        ? isNumber(e.target.value)
                          ? setAmount(e.target.value)
                          : null
                        : setAmount("")
                    }
                  />
                </div>
              </div>
              {pyMessage}
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button
                className={cls("btn", amount && "btn--primary")}
                type="submit"
                ref={payoutBtnRef}
              >
                Add
              </button>
            </div>
          </div>
        </form>
        {payout ? (
          <div
            className="overlay"
            onClick={() => {
              setPayout("");
              clearPayoutData();
            }}
          ></div>
        ) : null}
      </div>

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={(e) => {
          const files = e.target.files;
          if (files && files.length) {
            setPicture(files[0]);
          }
        }}
      />

      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-header">
            <div className="shipments-header__left">
              <h3 className="shipments-header__heading">Manage Riders</h3>
            </div>
            <div className="shipments-header__actions">
              <input
                type="text"
                className="form-input"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="btn btn--primary"
                style={{ flexShrink: 0 }}
                onClick={() => setRiderAction({ type: "add" })}
              >
                <IonIcon icon={addOutline} />
                New Rider
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Contact</th>
                  {/* <th>Verified</th> */}
                  <th>Transport</th>
                  {/* <th>Transport Verified</th> */}
                  <th>Username</th>
                  <th>Earnings</th>
                  <th>Carrier</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={10}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      Error fetching riders.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !riders.length ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      There are no riders at the moment
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && riders.length
                  ? riders.map((rider) => (
                      <tr key={rider._id}>
                        <td>
                          <span onClick={() => copyData(rider._id)}>
                            #{rider._id.slice(rider._id.length - 6)}
                          </span>
                        </td>
                        <td>
                          <div className="rider">
                            <img src={rider.ProfilePicturePath} alt="" />
                            <p>
                              {rider.FirstName} {rider.LastName}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>
                              <strong>Telephone:</strong> {rider.Telephone}
                            </p>
                            <p>
                              <strong>Email Address:</strong>{" "}
                              {rider.EmailAddress}
                            </p>
                          </div>
                        </td>
                        {/* <td>{rider.Verified ? "Verfied" : "Unverified"}</td> */}
                        <td>
                          <div>
                            <p>
                              <strong>Type:</strong>{" "}
                              {rider.ActiveTransport.Type.Name}
                            </p>
                            <p>
                              <strong>Description:</strong>{" "}
                              {rider.ActiveTransport.Description}
                            </p>
                            <p>
                              <strong>Color:</strong>{" "}
                              {rider.ActiveTransport.Color}
                            </p>
                          </div>
                        </td>
                        {/* <td>
                          {rider.ActiveTransport.Verified
                            ? "Verfied"
                            : "Unverified"}
                        </td> */}
                        <td>{rider.Username}</td>
                        <td>&#8358; {roundDP(rider.AvailableEarnings, 2)}</td>
                        <td>
                          {rider.Carrier ? rider.Carrier.Name : "Unassigned"}
                        </td>
                        <td>{rider.Status}</td>
                        <td>
                          <div className="shipments-menu">
                            <div className="shipments-menu__main">
                              <IonIcon icon={ellipsisVerticalOutline} />
                            </div>
                            <div className="shipments-menu__block">
                              <div className="shipments-menu__block-main">
                                <div onClick={() => setPayout(rider._id)}>
                                  <IonIcon icon={walletOutline} /> Add Payout
                                </div>
                                <Link to={`/shipments?rider=${rider._id}`}>
                                  <IonIcon icon={boatOutline} /> View Shipments
                                </Link>
                                <div onClick={() => updateRider(rider._id)}>
                                  <IonIcon icon={createOutline} /> Update Rider
                                </div>
                                {rider.Status !== "PENDING" ? (
                                  <div>
                                    <IonIcon icon={stopCircleOutline} />{" "}
                                    {rider.Status === "ACTIVE"
                                      ? "Suspend"
                                      : "Unsuspend"}{" "}
                                    Rider
                                  </div>
                                ) : null}
                              </div>
                              <div className="shipments-menu__block-top"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          {page !== 1 || riders.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Riders);
