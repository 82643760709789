import { MONTHS_SHORT } from "../data";

type Assert = <T>(data: T | null) => asserts data is T;
export const assertNotNull: Assert = <T>(data: T | null): asserts data is T => {
  if (data === null) {
    throw new Error(`null was found unexpectedly`);
  }
};

export const isNumber = (value: string) => {
  if (!value) return true;
  if ((value.match(/\./g)?.length || 0) > 1) return false;
  if (value.endsWith(".")) return true;

  const re = /^[0-9.\b]+$/;

  // if value is not blank, then test the regex
  return re.test(value);
};

export const getSkip = (page: number, division: number): number =>
  (page - 1) * division;

export const getDate = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const comp_date = `${day} ${MONTHS_SHORT[month]}, ${year}`;

  return `${comp_date}`;
};

export const getInputDate = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth() + 1;
  const year = new_date.getFullYear();

  const comp_date = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  return `${comp_date}`;
};

export const roundDP = (
  value: number | string,
  len: number,
  noCommas?: boolean
) => {
  // value = Number(value).toFixed(len);

  const [bfDec, afDec] = value.toString().split(".");

  let s = "";

  if (afDec) {
    s = afDec.slice(0, len);
  }

  for (let i = s.length; i < len; i++) {
    s += "0";
  }

  return noCommas ? `${bfDec}.${s}` : `${Number(bfDec).toLocaleString()}.${s}`;
};

export const getDateTime = (date_string: string) => {
  const new_date = new Date(date_string);

  const day = new_date.getDate();
  const month = new_date.getMonth();
  const year = new_date.getFullYear();

  const hours = new_date.getHours();
  const meridian = hours < 12 ? "AM" : "PM";

  let hours12format = hours % 12;
  hours12format = hours12format ? hours12format : 12;

  const comp_date = `${day} ${MONTHS_SHORT[month]} ${year}`;
  const comp_time = `${String(hours12format).padStart(2, "0")}:${String(
    new_date.getMinutes()
  ).padStart(2, "0")} ${meridian}`;

  return `${comp_date} ${comp_time}`;
};

export const copyData = (text: string, stateUpdate?: (val: any) => void) => {
  navigator.clipboard.writeText(text).then(
    () => {
      if (stateUpdate) {
        stateUpdate(true);
        const interval = window.setInterval(() => {
          stateUpdate(false);
          window.clearInterval(interval);
        }, 1500);
      }
    },
    () => {
      // Failed to copy do nothing
    }
  );
};
