import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { tRootState } from "../../store";

const withAuth =
  <P extends object>(Component: React.ComponentType) =>
  (props: P) => {
    const { loggedIn, accessToken, admin } = useSelector(
      (state: tRootState) => state.user
    );

    if (!loggedIn || !accessToken || !admin) return <Navigate to="/login" />;

    return <Component {...props} />;
  };

export default withAuth;
