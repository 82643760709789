import { useState, useRef, useEffect, FormEvent } from "react";
import { useSelector } from "react-redux";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  addOutline,
  boatOutline,
  closeOutline,
  createOutline,
  ellipsisVerticalOutline,
  peopleOutline,
  personOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tBranches, tCountry } from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import useAlert from "../../hooks/useAlert/useAlert";
import useData from "../../hooks/useData/useData";

import { assertNotNull, getSkip } from "../../utils/func";
import { compSelectHandler } from "../NewShipment/NewShipment";

const Branches = () => {
  const { accessToken, admin } = useSelector((state: tRootState) => state.user);

  assertNotNull(admin);

  const { countries } = useSelector((state: tRootState) => state.cache);

  const { fetchCountries, fetchBusinessSectors } = useData();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchInterval = useRef<number | null>(null);

  const [reload, setReload] = useState(false);

  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(1);
  const [division] = useState<number>(8);

  const [numRecords, setNumRecords] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [branches, setBranches] = useState<tBranches>([]);

  const [addBranch, setAddBranch] = useState(false);

  const [name, setName] = useState("");

  const addressRef = useRef<HTMLInputElement | null>(null);
  const [triggerAddressField, setTriggerAddressField] = useState(false);

  const [address, setAddress] = useState("");

  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState<tCountry | null>(null);

  const addBranchBtnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);
  const [message, setMessage, clearMessage] = useAlert();

  const [success, setSuccess] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const clearAddBranchData = () => {
    setName("");

    setAddress("");
    setAddress2("");
    setAddress3("");
    setSuburb("");
    setCity("");
    setPostalCode("");
    setState("");
    setCountry(null);
  };

  const addNewBranch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name || !address || !state || !city || !country)
      return setMessage("warning", "Fill in all required fields");

    addBranchBtnRef.current.innerHTML = `<span class="fas fa-spinner fa-spin"></span>`;
    addBranchBtnRef.current.setAttribute("disabled", "disabled");

    api_client({
      url: "/branches",
      method: "POST",
      headers: { Authorization: `Bearer ${accessToken}` },
      data: {
        Name: name,
        Address: address,
        Address2: address2,
        Address3: address3,
        PostalCode: postalCode,
        Suburb: suburb,
        City: city,
        Country: country!._id,
        State: state,
      },
    })
      .then((res) => {
        setAddBranch(false);
        clearAddBranchData();

        setSuccess({
          title: "Branch added!",
          message: "Branch added successfully",
        });

        setSearch("");
        setPage(1);
        setReload((rl) => !rl);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setMessage("warning", err.response.data.message);
        } else {
          setMessage("error", err.message);
        }
      })
      .finally(() => {
        addBranchBtnRef.current.removeAttribute("disabled");
        addBranchBtnRef.current.innerHTML = "Add Branch";
      });
  };

  useEffect(() => {
    clearMessage();
  }, [
    name,
    address,
    address2,
    address3,
    suburb,
    postalCode,
    city,
    state,
    country,
    clearMessage,
  ]);

  useEffect(() => {
    const fillInAddress = (autocomplete: any) => {
      setAddress("");
      setAddress2("");
      setAddress3("");
      setSuburb("");
      setCity("");
      setPostalCode("");
      setState("");
      setCountry(null);

      const place = autocomplete.getPlace();

      console.log(place);

      let street: string = "";
      let route: string = "";

      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];

        if (addressType === "street_number") {
          street = place.address_components[i]["long_name"];
        }

        if (addressType === "route") {
          route = place.address_components[i]["long_name"];
        }

        if (!street && route) setAddress(route);
        if (street && !route) setAddress(street);
        if (street && route) setAddress(`${street}, ${route}`);

        if (addressType === "sublocality_level_1")
          setAddress(place.address_components[i]["long_name"]);

        if (addressType === "neighborhood")
          setSuburb(place.address_components[i]["long_name"]);

        if (addressType === "locality")
          setCity(place.address_components[i]["long_name"]);
        else if (addressType === "postal_town")
          setCity(place.address_components[i]["long_name"]);

        if (addressType === "administrative_area_level_1") {
          setState(place.address_components[i]["long_name"]);
        }

        if (addressType === "country") {
          const country =
            countries.find(
              (country) =>
                country.Code === place.address_components[i]["short_name"]
            ) || null;
          setCountry(country);
        }

        if (addressType === "postal_code")
          setPostalCode(place.address_components[i]["long_name"]);
      }
    };

    if (addressRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressRef.current,
        { types: ["geocode"] }
      );
      autocomplete.setFields(["address_component"]);
      autocomplete.addListener("place_changed", () =>
        fillInAddress(autocomplete)
      );
    }
  }, [countries, triggerAddressField]);

  useEffect(() => {
    if (fetchInterval.current) window.clearInterval(fetchInterval.current);

    setLoading(true);
    setError(false);

    fetchInterval.current = window.setInterval(() => {
      api_client({
        url: `/branches?page=${page}&division=${division}&search=${search}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          setNumRecords(res.data.meta_data.num_records);
          setPagination(res.data.meta_data.pagination);

          setBranches(res.data.data);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoading(false);
          if (fetchInterval.current)
            window.clearInterval(fetchInterval.current);
        });
    }, 3000);
  }, [search, page, division, accessToken, reload]);

  useEffect(() => {
    fetchCountries();
    fetchBusinessSectors();
  }, [fetchCountries, fetchBusinessSectors]);

  const skip = getSkip(page, division);
  const start = skip + 1;
  const end = skip + division;

  return (
    <>
      {success ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>{success.title}</h3>
            <p>{success.message}</p>
            <div className="success-modal__btns">
              <button className="btn" onClick={() => setSuccess(null)}>
                Close
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setSuccess(null)}></div>
        </div>
      ) : null}
      <div>
        <form
          className={cls("modal modal--md", addBranch && "modal--open")}
          onSubmit={addNewBranch}
        >
          <div className="modal__header">
            <h3 className="modal__heading">Add New Branch</h3>
            <div className="modal__actions">
              <span
                className="modal__action"
                onClick={() => {
                  setAddBranch(false);
                  clearAddBranchData();
                }}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </div>
          </div>
          <div className="modal__body">
            <div className="form-flex">
              {message}
              <div className="form-grid">
                <div className="form-group">
                  <label>
                    Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    ref={(el) => {
                      addressRef.current = el;
                      setTriggerAddressField((taf) => !taf);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Address 2</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter address 2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Address 3</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter address 3"
                    value={address3}
                    onChange={(e) => setAddress3(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Suburb</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter suburb"
                    value={suburb}
                    onChange={(e) => setSuburb(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Postal code</label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter postal code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Country <span>*</span>
                  </label>
                  <select
                    className="form-select"
                    value={country!?._id || ""}
                    onChange={compSelectHandler<tCountry>(
                      countries,
                      setCountry
                    )}
                  >
                    <option value="">-- Select Country --</option>
                    {countries.map((cuntry) => (
                      <option key={cuntry._id} value={cuntry._id}>
                        {cuntry.Name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    State <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>
                    City <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Enter city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <div></div>
            <div>
              <button
                className={cls(
                  "btn",
                  name && address && city && state && country && "btn--primary"
                )}
                type="submit"
                ref={addBranchBtnRef}
              >
                Add Branch
              </button>
            </div>
          </div>
        </form>
        {addBranch ? (
          <div
            className="overlay"
            onClick={() => {
              setAddBranch(false);
              clearAddBranchData();
            }}
          ></div>
        ) : null}
      </div>
      <Header />
      <main className="main">
        <div className="container">
          <div className="shipments-header">
            <div className="shipments-header__left">
              <h3 className="shipments-header__heading">Manage Branches</h3>
            </div>
            <div className="shipments-header__actions">
              <input
                type="text"
                className="form-input"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button
                className="btn btn--primary"
                style={{ flexShrink: 0 }}
                onClick={() => setAddBranch(true)}
              >
                <IonIcon icon={addOutline} />
                New Branch
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>Country</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={10}>
                      <VerticalBarLoader sm />
                    </td>
                  </tr>
                ) : null}
                {error ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      Error fetching branches.{" "}
                      <span
                        className="link"
                        onClick={() => setReload((rl) => !rl)}
                      >
                        Reload
                      </span>
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && page === 1 && !branches.length ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      There are no branches at the moment
                    </td>
                  </tr>
                ) : null}
                {!loading && !error && branches.length
                  ? branches.map((branch) => (
                      <tr key={branch._id}>
                        <td>{branch.Name}</td>
                        <td>{branch.Address}</td>
                        <td>{branch.State}</td>
                        <td>{branch.Country.Name}</td>
                        <td>
                          <div className="shipments-menu">
                            <div className="shipments-menu__main">
                              <IonIcon icon={ellipsisVerticalOutline} />
                            </div>
                            <div className="shipments-menu__block">
                              <div className="shipments-menu__block-main">
                                <div>
                                  <IonIcon icon={boatOutline} /> View Shipments
                                </div>
                                <div>
                                  <IonIcon icon={peopleOutline} /> View
                                  Customers
                                </div>
                                <div>
                                  <IonIcon icon={personOutline} /> View Admins
                                </div>
                                <div>
                                  <IonIcon icon={createOutline} /> Update Branch
                                </div>
                              </div>
                              <div className="shipments-menu__block-top"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
          {page !== 1 || branches.length ? (
            <div className="shipments-footer">
              <p>
                Showing {start} to {end} of {numRecords} entries
              </p>
              <div className="pagination">
                {page !== 1 ? (
                  <>
                    <button onClick={() => setPage(1)}>&#171;</button>
                    <button onClick={() => setPage((page) => page - 1)}>
                      &#8249;
                    </button>
                  </>
                ) : null}
                {page === pagination && page !== 1 ? (
                  <button onClick={() => setPage((page) => page - 1)}>
                    {page - 1}
                  </button>
                ) : null}
                <button className="active">{page}</button>
                {page < pagination ? (
                  <button onClick={() => setPage((page) => page + 1)}>
                    {page + 1}
                  </button>
                ) : null}
                {page !== pagination ? (
                  <>
                    <button onClick={() => setPage((page) => page + 1)}>
                      &#8250;
                    </button>
                    <button onClick={() => setPage(pagination)}>&#187;</button>
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(Branches);
