import { useState, ReactNode } from "react";

import { tCharge } from "../../store/types/shipping.types";
import SelectCharge from "../../components/SelectCharge/SelectCharge";

type tTriggerFunction = React.Dispatch<
  React.SetStateAction<tCharge | null>
> | null;

const useSelectCharge = (): [ReactNode, (func: tTriggerFunction) => void] => {
  const [triggerFunction, setTriggerFunction] =
    useState<tTriggerFunction>(null);

  const defaultTriggerFunction = (cargo: tCharge) => null;

  const updateTriggerFunction = (func: tTriggerFunction) => {
    setTriggerFunction(() => func);
  };

  const closeHandler = () => {
    setTriggerFunction(null);
  };

  const render = (
    <SelectCharge
      selectHandler={triggerFunction || defaultTriggerFunction}
      open={!!triggerFunction}
      closeHandler={closeHandler}
    />
  );

  return [render, updateTriggerFunction];
};

export default useSelectCharge;
