import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.code === "ERR_BAD_REQUEST" && error.response.status === 401) {
      localStorage.removeItem("appState");
      window.location.assign("/login");
    }
    return Promise.reject(error);
  }
);

export default instance;
